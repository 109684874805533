import { injectable } from "tsyringe";
import { SEA } from "gun/gun";
import { WaterGunDB as GunDB } from "./WaterGunDB";
import { State, stateInstance } from "./WaterGunState";

@injectable()
export class WaterGun {
    get gun() {
        return this.gundb.instance;
    }
    user: any;
    userInfo: any;
    state: State = stateInstance;

    constructor(public gundb: GunDB) {
        this.saveProfile = this.saveProfile.bind(this);
        this.addUpdateGameOnList = this.addUpdateGameOnList.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.deleteFromMyGames = this.deleteFromMyGames.bind(this);
        this.createNewAccount = this.createNewAccount.bind(this);
    }

    async userAuth() {
        if (this.user) {
            return;
        }

        this.userInfo = await this.getUserInfo();

        const user = this.gun.user();

        await user.auth(this.userInfo.pair);

        this.user = user;

        const epub = await this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("epub");
        if (!epub) {
            await this.gun
                .get("~" + this.userInfo.pair.pub)
                .get("epub")
                .put(this.userInfo.pair.epub);
        }

        let userProfile = await this.getUserProfile();
        if (!userProfile) {
            await this.saveProfile("PlayerName", "#cccccc", "");
            userProfile = await this.getUserProfile();
            await this.gun
                .get("~" + this.userInfo.pair.pub)
                .get("epub")
                .put(this.userInfo.pair.epub);
        }

        this.state.isLoggedIn.next(true);

        return userProfile;
    }

    async shareInfoWithFriend(
        friendPath: string,
        dataLabel: string,
        data: any
    ) {
        const friend = await this.gun.get(friendPath);
        const epub = friend.epub;
        var enc = await SEA.encrypt(
            data,
            await (<any>SEA).secret(epub, this.userInfo.pair)
        );
        if (!enc) {
            return;
        }
        await this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("friends")
            .get(friendPath)
            .get("shared")
            .get(dataLabel)
            .put(enc, (ack: any) => {
                if (ack.err) {
                    console.error("put share profile error", ack.err);
                }
            });
    }

    async checkSharedProfileWithFriend(friendID: string) {
        let encProfile = await this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("friends")
            .get(friendID)
            .get("shared")
            .get("profile");
        if (!encProfile) {
            const profile = await this.getUserProfile();
            await this.shareInfoWithFriend(friendID, "profile", profile);
        }
    }

    async getUserInfo(): Promise<{
        pair: { pub: string; priv: string; epub: string; epriv: string };
    }> {
        const strUser = localStorage.getItem("user");
        const objUser = strUser ? JSON.parse(strUser) : { pair: null };
        let pair = objUser.pair;
        if (!pair) {
            pair = <any>await SEA.pair();
            objUser.pair = pair;
            localStorage.setItem("user", JSON.stringify(objUser));
        }
        return objUser;
    }

    async saveProfile(strName: string, color: string, icon: string) {
        const profile = {
            name: strName,
            color: color,
            icon: icon,
        };

        await this.userAuth();

        const enc = await SEA.encrypt(profile, this.userInfo.pair);

        await this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("profile")
            .put(enc);
        this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("friends")
            .map()
            .once((val: any, key: string) => {
                this.shareInfoWithFriend(key, "profile", profile);
            });
    }

    async getUserProfile(): Promise<any> {
        let pub = this.userInfo.pair.pub;
        let profile = await this.gun.get("~" + pub).get("profile");
        if (!profile) {
            return null;
        }
        const decProfile = await SEA.decrypt(
            profile,
            this.userInfo.pair
        ).then();

        return decProfile;
    }

    listenToMyGameMoves(gameKey: string, prop = "lastMove") {
        this.gun
            .get(gameKey)
            .get(prop)
            .on(
                async (move: any, key: string, msg: string, eve: any) => {
                    this.gameEves[gameKey] = eve;
                    const game = await this.gun.get(gameKey);
                    await this.addUpdateGameOnList(game, gameKey);
                },
                { changes: true }
            );
    }

    private gameEves: { [id: string]: any } = [];
    private getGamePropsForUserFunc: (
        game: any,
        key: string
    ) => Promise<{
        isUsersTurn: boolean;
        opponentID: string;
        resultCode: string;
    }>;

    listenToMyGames(prop: string) {
        this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("games")
            .map()
            .once((gameNode: any, key: string) => {
                if (gameNode) {
                    this.listenToMyGameMoves(key, prop);
                }
            });
    }

    initPlayerGamesList(
        getGamePropsForUserFunc: (
            game: any,
            key: string
        ) => Promise<{
            isUsersTurn: boolean;
            opponentID: string;
            resultCode: string;
        }>
    ) {
        this.getGamePropsForUserFunc = getGamePropsForUserFunc;
        this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("games")
            .map((x: any) => (!x ? undefined : x))
            .once(this.addUpdateGameOnList);
    }

    async addUpdateGameOnList(game: any, key: string) {
        const gameID = key.slice(1);
        if (!game) {
            return;
        }

        let { isUsersTurn, opponentID, resultCode } =
            await this.getGamePropsForUserFunc(game, key);

        const pub = "~" + this.userInfo.pair.pub;

        let profile = null;

        if (this.state.friends[opponentID]) {
            profile = this.state.friends[opponentID].getValue();
        } else if (opponentID && opponentID.length > 0) {
            const opponent = await this.gun.get(opponentID);
            const encOppProfile = await this.gun
                .get(opponentID)
                .get("friends")
                .get(pub)
                .get("shared")
                .get("profile");
            //TODO: share encrypted keys instead of profiles?
            //then you will need to decrypt key and then decrypt profile
            //maybe share keys when the data being shared is large
            if (encOppProfile) {
                profile = <any>(
                    await SEA.decrypt(
                        encOppProfile,
                        await (<any>SEA).secret(
                            opponent.epub,
                            this.userInfo.pair
                        )
                    )
                );
            }
            if (opponentID && profile) {
                this.state.addFriend(opponentID, profile);
            }
        }

        if (profile) {
            let oppName = profile.name;
            stateInstance.addGameToList(
                gameID,
                oppName,
                game.created,
                isUsersTurn,
                resultCode
            );
        } else {
            stateInstance.addGameToList(
                gameID,
                "Waiting...",
                game.created,
                isUsersTurn,
                resultCode
            );
        }
    }

    async deleteFromMyGames(gameID: string) {
        await this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("games")
            .get("~" + gameID)
            .put(null);

        const games = this.state.games.value;
        const index = games.findIndex((game) => {
            return game.id == gameID;
        });
        if (index < 0) {
            return;
        }
        games.splice(index, 1);
        if (this.gameEves["~" + gameID]) {
            this.gameEves["~" + gameID].off();
        }
        this.state.games.next(games);
    }

    logIn(strUserKeys: string) {
        const objUserKeys = JSON.parse(strUserKeys);
        if (
            !objUserKeys.pub ||
            !objUserKeys.epub ||
            !objUserKeys.priv ||
            !objUserKeys.epriv
        ) {
            console.error("part of user key is missing");
            return;
        }
        localStorage.setItem("user", JSON.stringify({ pair: objUserKeys }));
        location.reload();
    }

    logOut() {
        localStorage.removeItem("user");
        location.href = location.origin;
    }

    createNewAccount() {
        this.getUserInfo();
        this.userAuth();
    }

    async getFriendProfile(
        friendID: string,
        friendEPub?: string
    ): Promise<any> {
        const friend = this.state.friends[friendID];

        if (friend) {
            const profile = friend.value;
            return profile;
        }

        if (!friendEPub || friendEPub.length == 0) {
            friendEPub = await this.gun.get(friendID).get("epub");
        }
        let profile;
        const pub = "~" + this.userInfo.pair.pub;
        if (friendEPub) {
            const encOppProfile = await this.gun
                .get(friendID)
                .get("friends")
                .get(pub)
                .get("shared")
                .get("profile");
            if (encOppProfile) {
                profile = <any>(
                    await SEA.decrypt(
                        encOppProfile,
                        await (<any>SEA).secret(friendEPub, this.userInfo.pair)
                    )
                );
            }
        }
        if (profile) {
            this.state.addFriend(friendID, profile);
            return profile;
        }
    }

    addGameToUser(gameID: string) {
        let gameLink = this.gun.get("~" + gameID);
        this.gun
            .get("~" + this.userInfo.pair.pub)
            .get("games")
            .set(gameLink);
    }
}
