import { injectable } from "tsyringe";
import "share-api-polyfill";
import { ChessGameMP } from "./ChessGameMP";
import { ChessState, stateInstance } from "./ChessState";
import { WaterGunDB as GunDB } from "./WaterGun/WaterGunDB";
import { ChessGameSP } from "./ChessGameSP";
import { Helper } from "./Helpers";
import { WaterGun } from "./WaterGun/WaterGun";
import * as clipboard from "clipboard-polyfill/text";

@injectable()
export class App {
    get gun() {
        return this.gundb.instance;
    }
    get userInfo() {
        return this.waterGun.userInfo;
    }
    state: ChessState = stateInstance;

    constructor(
        public gundb: GunDB,
        public mpgame: ChessGameMP,
        public spgame: ChessGameSP,
        public waterGun: WaterGun
    ) {
        this.undoMoveSP = this.undoMoveSP.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.loadGame = this.loadGame.bind(this);
        this.deleteFromMyGames = this.deleteFromMyGames.bind(this);
        this.inviteFriend = this.inviteFriend.bind(this);
        this.loadGameSP = this.loadGameSP.bind(this);
        this.createNewGameSP = this.createNewGameSP.bind(this);
        this.createNewGameMP = this.createNewGameMP.bind(this);
        this.downloadPrivateKey = this.downloadPrivateKey.bind(this);
        this.copyKeysToClipboard = this.copyKeysToClipboard.bind(this);
        this.resignMP = this.resignMP.bind(this);
        this.resignSP = this.resignSP.bind(this);
        this.createNewAccount = this.createNewAccount.bind(this);
        this.getGamePropsForUser = this.getGamePropsForUser.bind(this);
        this.requestDraw = this.requestDraw.bind(this);
        this.agreeDraw = this.agreeDraw.bind(this);
        this.denyDraw = this.denyDraw.bind(this);
        this.logIn = this.logIn.bind(this);
        this.logOut = this.logOut.bind(this);
        this.setThemeMode = this.setThemeMode.bind(this);

        stateInstance.themeMode.subscribe((val) => {
            if (val === "dark") {
                document.body.classList.add("dark");
            } else {
                document.body.classList.remove("dark");
            }
        });

        const themeMode = localStorage.getItem("themeMode");
        if (themeMode === "dark") {
            stateInstance.themeMode.next(themeMode);
        }

        mpgame.addEventListener("load", () => {
            this.state.hasStarted.next(mpgame.hasStarted);
            this.state.isSpectating.next(mpgame.spectator);
            (async () => {
                this.updateMessageStateMP();
                this.updateTurnState();
                this.getOpponent(this.mpgame.oppPub);
                this.state.side.next(mpgame.side);
                this.state.loaded.next(true);
            })();
        });

        mpgame.addEventListener("next", () => {
            this.updateMessageStateMP();
        });

        spgame.addEventListener("load", () => {
            this.state.isPlayersTurn.next(this.spgame.turn == "white");
            this.updateMessageStateSP();
        });

        spgame.addEventListener("move", () => {
            if (this.spgame.turn.charAt(0) == this.spgame.side) {
                this.state.isPlayersTurn.next(true);
            } else {
                this.state.isPlayersTurn.next(false);
            }
            this.updateMessageStateSP();
            localStorage.setItem("fen", this.spgame.fen);
            localStorage.setItem("status", JSON.stringify(this.spgame.status));
            localStorage.setItem(
                "history",
                JSON.stringify(this.spgame.history)
            );
            localStorage.setItem(
                "captured",
                JSON.stringify(this.spgame.getCaptured())
            );
        });

        mpgame.addEventListener("error", (message: string) => {
            this.state.errorMessage.next(message);
            this.state.errorMessage.next("");
        });

        mpgame.addEventListener("started", () => {
            this.state.hasStarted.next(true);
            const opponent = this.state.opponentName.getValue();
            if (opponent == "") {
                this.getOpponent(this.mpgame.oppPub);
            }
            this.checkSharedProfileWithOpponent();
        });
        mpgame.addEventListener("move", () => {
            this.updateMoveMessageStateMP();
            this.updateTurnState();
        });
        mpgame.addEventListener("new", (gameID: string) => {
            this.waterGun.addGameToUser(gameID);
            (async () => {
                let gameLink = this.gun.get("~" + gameID);
                const pub = "~" + this.userInfo.pair.pub;
                const game = await gameLink;
                const oppSide = game.b && game.b["#"] === pub ? "w" : "b";
                if (game[oppSide] && game[oppSide]["#"]) {
                    const profile = await this.waterGun.getUserProfile();
                    this.waterGun.shareInfoWithFriend(
                        game[oppSide]["#"],
                        "profile",
                        profile
                    );
                } else if (oppSide == "w") {
                    this.resetOpponent();
                }
            })();
        });

        let gameID = localStorage.getItem("gameID");

        (async () => {
            let hash = location.hash;

            if (hash) {
                gameID = hash;
                if (gameID.indexOf("#") === 0) {
                    gameID = gameID.substr(1, gameID.length);
                }
            } else if (gameID) {
                location.hash = "#" + gameID;
            }

            if ((!gameID || gameID == "") && !localStorage.getItem("user")) {
                //don't create an account unless we need to
                this.loadGameSP();
                this.listenToHashChange();
                return;
            }

            await this.init(gameID);
        })();
    }

    listenToHashChange() {
        window.addEventListener("hashchange", (event) => {
            const hash = location.hash;
            if (hash?.indexOf("#") === 0) {
                const gameID = hash.substr(1, hash.length);
                this.init(gameID);
            }
        });
    }

    private _initted = false;

    async init(gameID?: string) {
        if (this._initted) {
            if (gameID) {
                this.spgame.reset();
                this.state.isPlayingComputer.next(false);
                this.state.loaded.next(false);
                this.state.gameID.next(gameID);
                this.messagesForGame.length = 0;
                this.mpgame.loadGame(gameID, this.userInfo.pair);
                localStorage.setItem("gameID", gameID);
            }
            return;
        }

        const userProfile = await this.waterGun.userAuth();

        if (userProfile) {
            this.state.playerName.next(userProfile.name);
            this.state.playerIcon.next(userProfile.icon);
            this.state.playerColor.next(userProfile.color);
        }

        if (!gameID || gameID === "null") {
            this.loadGameSP();
        } else {
            this.spgame.reset();
            this.state.isPlayingComputer.next(false);
            this.state.loaded.next(false);
            this.state.gameID.next(gameID);
            this.messagesForGame.length = 0;
            this.mpgame.loadGame(gameID, this.userInfo.pair);
        }

        localStorage.setItem("gameID", gameID);

        this.waterGun.initPlayerGamesList(this.getGamePropsForUser);
        this.waterGun.listenToMyGames("lastMove");

        this.listenToHashChange();

        this._initted = true;
    }

    async checkSharedProfileWithOpponent() {
        const oppSide = this.mpgame.side == "w" ? "b" : "w";
        const game = await this.gun.get("~" + this.mpgame.gameID);
        if (game[oppSide]) {
            this.waterGun.checkSharedProfileWithFriend(game[oppSide]["#"]);
        }
    }

    resetOpponent() {
        this.state.opponentName.next("");
        this.state.opponentIcon.next("");
        this.state.opponentColor.next("#cccccc");
    }

    _getOpponentTime = 0;

    async getOpponent(oppPub?: string) {
        const timeSince = Date.now() - this._getOpponentTime;
        if (timeSince < 500) {
            return;
        }

        this._getOpponentTime = Date.now();
        let friendID = oppPub;

        if (!oppPub || oppPub.length === 0) {
            const game = await this.gun.get("~" + this.mpgame.gameID);
            const pub = "~" + this.userInfo.pair.pub;
            const oppSide = game.b["#"] === pub ? "w" : "b";
            if (!game[oppSide]) {
                this._getOpponentTime = 0;
                this.resetOpponent();
                return;
            }
            friendID = game[oppSide]["#"] ? game[oppSide]["#"] : null;
        }

        let profile = await this.waterGun.getFriendProfile(friendID);

        if (profile) {
            this.state.opponentName.next(profile.name);
            this.state.opponentIcon.next(profile.icon);
            this.state.opponentColor.next(profile.color);
        } else {
            this.resetOpponent();
        }

        this._getOpponentTime = 0;

        if (!profile && friendID) {
            setTimeout(() => {
                this.getOpponent(friendID);
            }, 500);
        }
    }

    async saveProfile(strName: string, color: string, icon: string) {
        this.state.playerName.next(strName);
        this.state.playerIcon.next(icon);
        this.state.playerColor.next(color);
        this.waterGun.saveProfile(strName, color, icon);
    }

    updateMessageStateSP() {
        if (this.spgame.inCheck() && !this.spgame.inCheckmate()) {
            this.setMessage("Check", false);
        }
        if (this.spgame.isGameOver()) {
            this.state.isGameOver.next(true);
            if (this.spgame.inCheckmate()) {
                this.state.confirm.next({
                    message: "Checkmate",
                    actions: [{ text: "Restart", func: this.createNewGameSP }],
                });
                this.state.confirm.next({
                    message: "",
                    actions: null,
                });
            }
        } else {
            this.state.isGameOver.next(false);
        }
    }

    messagesForGame: Array<string> = [];

    setMessage(message: string, dontRepeat = true) {
        if (dontRepeat) {
            if (this.messagesForGame.indexOf(message) > -1) {
                return;
            }
            this.messagesForGame.push(message);
        }
        this.state.message.next(message);
        this.state.message.next(null); // so that message will notify when prev message is same
    }

    updateMoveMessageStateMP() {
        if (this.mpgame.inCheck() && !this.mpgame.inCheckmate()) {
            this.setMessage("Check", false);
        }

        if (this.mpgame.drawRequested && !this.mpgame.inDraw()) {
            if (
                !this.mpgame.spectator &&
                this.mpgame.drawRequested != this.mpgame.side
            ) {
                stateInstance.confirm.next({
                    message: "Opponent requested a draw. Agree?",
                    actions: [
                        { text: "Yes", func: this.mpgame.setGameOverByDraw },
                        { text: "No", func: this.mpgame.denyDraw },
                    ],
                });
                stateInstance.confirm.next({
                    message: "",
                    actions: null,
                });
            } else {
                let name = "You";
                if (this.mpgame.spectator) {
                    if (this.mpgame.drawRequested == "b") {
                        name = "Black";
                    } else {
                        name = "White";
                    }
                }
                this.setMessage(`${name} requested a draw`, false);
            }
        }

        if (this.mpgame.drawDenied) {
            let name = "Opponent";
            if (this.mpgame.drawDenied == this.mpgame.side) {
                name = "You";
            }
            if (this.mpgame.spectator) {
                if (this.mpgame.drawDenied == "b") {
                    name = "Black";
                } else {
                    name = "White";
                }
            }
            this.setMessage(`${name} denied a draw`, false);
        }
        if (this.mpgame.spectator) {
            this.setMessage("You are a spectator of this game.");
        }
    }

    updateMessageStateMP() {
        if (this.mpgame.inDraw()) {
            this.state.isGameOver.next(true);
            this.setMessage("Draw");
        }

        if (this.mpgame.isGameOver()) {
            this.state.isGameOver.next(true);
            if (this.mpgame.nextGame) {
                stateInstance.messageLink.next({
                    text: "Next Game",
                    link: "#" + this.mpgame.nextGame,
                });
            }
            if (this.mpgame.inCheckmate()) {
                this.setMessage("Checkmate");
            }
            if (this.mpgame.resigned) {
                let side = "White";
                if (this.mpgame.resigned == "b") {
                    side = "Black";
                }
                this.setMessage(`${side} Resigned`);
            }
            if (this.mpgame.winner == this.mpgame.side) {
                this.setMessage("You Won!");
                this.state.playerWon.next(true);
            } else {
                if (this.mpgame.winner == "w") {
                    this.setMessage("White Won");
                    this.state.opponentWon.next(true);
                } else if (this.mpgame.winner == "b") {
                    this.setMessage("Black Won");
                    this.state.opponentWon.next(true);
                } else {
                    this.state.playerWon.next(false);
                    this.state.opponentWon.next(false);
                }
            }
        } else {
            this.state.playerWon.next(false);
            this.state.opponentWon.next(false);
            this.state.isGameOver.next(false);

            if (
                this.mpgame.isFirstMove() &&
                this.mpgame.side == "w" &&
                !this.mpgame.spectator
            ) {
                this.setMessage("Your move");
            }
        }
        if (this.mpgame.spectator) {
            this.setMessage("You are a spectator of this game.");
        }
    }

    updateTurnState() {
        if (this.mpgame.turn() == this.mpgame.side) {
            this.state.isPlayersTurn.next(true);
        } else {
            this.state.isPlayersTurn.next(false);
        }
    }

    loadGame(id: string) {
        if ("#" + id == location.hash) {
            return;
        }
        this.state.messageLink.next({
            text: "",
            link: "",
        });
        this.spgame.reset();
        this.state.isPlayingComputer.next(false);
        this.state.loaded.next(false);
        this.state.gameID.next(id);
        this.messagesForGame.length = 0;
        location.hash = "#" + id;
    }

    async createNewGameMP(callback?: Function) {
        if (!this.userInfo || !this._initted) {
            await this.init();
        }
        this.spgame.reset();
        const gameID = await this.mpgame.createNewGame(this.userInfo.pair);
        location.hash = "#" + gameID;
        if (callback) {
            callback();
        }
    }

    createNewGameSP() {
        localStorage.removeItem("fen");
        localStorage.removeItem("status");
        localStorage.removeItem("history");
        localStorage.removeItem("captured");
        this.loadGameSP();
    }

    loadGameSP() {
        this.mpgame.reset();
        let aiLevel = localStorage.getItem("aiLevel");
        const fen = localStorage.getItem("fen");
        let captured = localStorage.getItem("captured");
        if (captured) {
            try {
                captured = JSON.parse(captured);
            } catch {
                captured = null;
            }
        }
        let status = localStorage.getItem("status");
        if (status) {
            try {
                status = JSON.parse(status);
            } catch {
                status = null;
            }
        }
        let history = localStorage.getItem("history");
        if (history) {
            try {
                history = JSON.parse(history);
            } catch {
                history = null;
            }
        }
        this.spgame.createNewGame(status || fen, history, captured);
        if (aiLevel) {
            this.setComputerAILevel(parseInt(aiLevel));
        } else {
            aiLevel = "" + stateInstance.computerAILevel.getValue();
        }
        this.state.gameID.next("");
        this.messagesForGame.length = 0;
        this.state.isSpectating.next(false);
        this.state.isPlayingComputer.next(true);
        this.state.hasStarted.next(true);
        this.state.opponentName.next(`Computer L${parseInt(aiLevel) + 1}`);
        this.state.opponentIcon.next("desktop");
        this.state.opponentColor.next("#cccccc");
        localStorage.setItem("gameID", "");
        location.hash = "";
    }

    undoMoveSP() {
        if (this.spgame.turn === "white") {
            this.spgame.undoMove();
        }
    }

    async deleteFromMyGames(gameID: string) {
        if (confirm("Remove game from list?")) {
            this.waterGun.deleteFromMyGames(gameID);
        }
    }

    showShare() {
        navigator.share({
            title: "DecentChess Invite",
            text: "",
            url: location.href,
        });
    }

    async inviteFriend() {
        if (!this.mpgame.gameID) {
            await this.createNewGameMP();
        }
        this.showShare();
    }

    downloadPrivateKey() {
        return Helper.Download(
            "AB_Chess_PRIVATE_key.txt",
            JSON.stringify(this.userInfo.pair)
        );
    }

    logOut() {
        localStorage.removeItem("gameID");
        localStorage.removeItem("fen");
        localStorage.removeItem("status");
        localStorage.removeItem("history");
        localStorage.removeItem("aiLevel");
        this.waterGun.logOut();
    }

    logIn(strUserKeys: string) {
        this.waterGun.logIn(strUserKeys);
    }

    copyKeysToClipboard() {
        if (!this.userInfo) {
            return false;
        }
        const strKeys = JSON.stringify(this.userInfo.pair);
        clipboard.writeText(strKeys);
        return true;
    }

    createNewAccount() {
        this.waterGun.createNewAccount();
        this.init();
    }

    resignSP() {
        if (this.spgame.turn === "white") {
            if (confirm("Are you sure you want to resign?")) {
                this.createNewGameSP();
            }
        }
    }

    resignMP() {
        if (confirm("Are you sure you want to resign?")) {
            this.mpgame.resign();
        }
    }

    requestDraw() {
        if (confirm("Are you sure you want to request a draw?")) {
            this.mpgame.requestDraw();
        }
    }

    agreeDraw() {
        if (confirm("Are you sure you want to agree to a draw?")) {
            this.mpgame.setGameOverByDraw();
        }
    }

    denyDraw() {
        if (confirm("Are you sure you want to agree to a draw?")) {
            this.mpgame.denyDraw();
        }
    }

    async getGamePropsForUser(
        game: any,
        key: string
    ): Promise<{
        isUsersTurn: boolean;
        opponentID: string;
        resultCode: string;
    }> {
        if (!game.fen) {
            return {
                isUsersTurn: false,
                opponentID: "",
                resultCode: "",
            };
        }
        const fenParts = game.fen.split(" ");
        let turn = fenParts[1];
        if (!turn) {
            let firstMove = await this.gun.get(key).get("firstMove");
            let oppPub = "";
            if (firstMove) {
                if (firstMove.length > 0) {
                    firstMove = JSON.parse(firstMove);
                }
                oppPub = firstMove.userPub;
            }
            if (!oppPub || oppPub == "") {
                turn = "w";
            } else {
                turn = "b";
            }
        }
        const pub = "~" + this.userInfo.pair.pub;
        const side = game.b && game.b["#"] === pub ? "b" : "w";

        let isUsersTurn = side == turn;
        const oppSide = game.b && game.b["#"] === pub ? "w" : "b";
        let opponentID = "";
        let result = "";
        if (!game[oppSide]) {
            game;
            return {
                isUsersTurn: isUsersTurn,
                opponentID: opponentID,
                resultCode: result,
            };
        }
        if (game[oppSide] && game[oppSide]["#"]) {
            opponentID = game[oppSide]["#"];
        }

        if (game.lastMove && game.lastMove.length > 0) {
            const lastMove = JSON.parse(game.lastMove);

            if (lastMove.drawRequest) {
                if (lastMove.drawRequest == side) {
                    isUsersTurn = false;
                } else {
                    isUsersTurn = true;
                }
            }

            if (lastMove.winner) {
                if (lastMove.winner == side) {
                    result = "W";
                } else {
                    result = "L";
                }
                if (lastMove["viewed_" + side]) {
                    isUsersTurn = false;
                }
            }
            if (lastMove.draw) {
                result = "D";
                if (lastMove["viewed_" + side]) {
                    isUsersTurn = false;
                }
            }
        }
        return {
            isUsersTurn: isUsersTurn,
            opponentID: opponentID,
            resultCode: result,
        };
    }

    setComputerAILevel(value: number) {
        this.state.opponentName.next(`Computer L${value + 1}`);
        localStorage.setItem("aiLevel", "" + value);
        stateInstance.computerAILevel.next(value);
        this.spgame.setAILevel(value);
    }

    setThemeMode(mode: "light" | "dark") {
        stateInstance.themeMode.next(mode);
        localStorage.setItem("themeMode", mode);
    }
}
