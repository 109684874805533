import React, { useState, useEffect } from "react";
import { App } from "../../../App";
import PlayerName from "../components/PlayerName/PlayerName";
import { stateInstance } from "../../../ChessState";
import PlayerAvatar from "../components/PlayerAvatar/PlayerAvatar";
import { SnackbarProvider } from "notistack";
import Message from "../components/Message/Message";
import SpeedDialMenu from "../components/SpeedDialMenu/SpeedDialMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHandshake,
    faChessKing,
    faUndo,
    faFlag,
} from "@fortawesome/free-solid-svg-icons";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Subscription } from "rxjs";

interface PageBottomProps {
    app: App;
}

const PageBottom: React.FC<PageBottomProps> = ({ app }) => {
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [messageLink, setMessageLink] = useState({ link: null, text: null });
    const [isPlayingComputer, setIsPlayingComputer] = useState(false);
    const [playerName, setPlayerName] = useState("");
    const [playerColor, setPlayerColor] = useState("");
    const [playerIconName, setPlayerIconName] = useState("");
    const [isPlayersTurn, setIsPlayersTurn] = useState(false);
    const [playerWon, setPlayerWon] = useState(false);
    const [locHash, setLocHash] = useState(location.hash);
    const [hasStarted, setHasStarted] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [isSpectating, setIsSpectating] = useState(false);
    const [confirm, setConfirm] = useState({
        message: "",
        actions: [],
    });
    const [themeMode, setThemeMode] = useState<"dark" | "light">("light");
    const [closeSnackBar, setCloseSnackBar] = useState(false);

    const myTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: themeMode,
                },
            }),
        [themeMode]
    );

    const subs: Array<Subscription> = [];

    useEffect(() => {
        subs.push(
            stateInstance.isPlayingComputer.subscribe((val: boolean) => {
                setIsPlayingComputer(val);
            })
        );
        subs.push(
            stateInstance.hasStarted.subscribe((val: boolean) => {
                setHasStarted(val);
            })
        );
        subs.push(
            stateInstance.playerName.subscribe((val: string) => {
                setPlayerName(val);
            })
        );
        subs.push(
            stateInstance.playerColor.subscribe((val: string) => {
                setPlayerColor(val);
            })
        );
        subs.push(
            stateInstance.playerIcon.subscribe((val: string) => {
                setPlayerIconName(val);
            })
        );
        subs.push(
            stateInstance.isPlayersTurn.subscribe((val: boolean) => {
                setIsPlayersTurn(val);
            })
        );
        subs.push(
            stateInstance.message.subscribe((val: string) => {
                setCloseSnackBar(false);
                setMessage(val);
            })
        );
        subs.push(
            stateInstance.playerWon.subscribe((val: any) => {
                setPlayerWon(val);
            })
        );
        subs.push(
            stateInstance.isGameOver.subscribe((val: any) => {
                setIsGameOver(val);
            })
        );
        subs.push(
            stateInstance.confirm.subscribe((val: any) => {
                setCloseSnackBar(false);
                setConfirm(val);
            })
        );
        subs.push(
            stateInstance.errorMessage.subscribe((val: any) => {
                setErrorMessage(val);
            })
        );
        subs.push(
            stateInstance.themeMode.subscribe((val: "light" | "dark") => {
                setThemeMode(val);
            })
        );
        subs.push(
            stateInstance.isSpectating.subscribe((val: boolean) => {
                setIsSpectating(val);
            })
        );
        subs.push(
            stateInstance.messageLink.subscribe(
                (val: { link: string; text: string }) => {
                    setCloseSnackBar(false);
                    setMessageLink(val);
                }
            )
        );
        subs.push(
            stateInstance.gameID.subscribe((val: string) => {
                setCloseSnackBar(true);
            })
        );

        window.addEventListener("hashchange", updateLocHash);

        return () => {
            while (subs.length) {
                subs.pop().unsubscribe();
            }
            window.removeEventListener("hashchange", updateLocHash);
        };
    }, [stateInstance]);

    const updateLocHash = () => {
        setLocHash(location.hash);
    };

    const gameActionsSP = [
        {
            icon: <FontAwesomeIcon icon={faUndo} />,
            name: "Undo Move",
            func: app.undoMoveSP,
        },
        {
            icon: <FontAwesomeIcon icon={faChessKing} rotation={270} />,
            name: "Resign",
            func: app.resignSP,
        },
    ];

    const gameActionsMP = [
        {
            icon: <FontAwesomeIcon icon={faHandshake} />,
            name: "Draw",
            func: app.requestDraw,
        },
        {
            icon: <FontAwesomeIcon icon={faChessKing} rotation={270} />,
            name: "Resign",
            func: app.resignMP,
        },
    ];

    let avatarStyle = {
        width: "6vh",
        height: "6vh",
        fontSize: "1em",
        marginBottom: "0.2em",
    };

    const playerSelected = (!isGameOver && isPlayersTurn) || playerWon;

    if (playerSelected) {
        avatarStyle.marginBottom = "-.2em";
    }

    return (
        <ThemeProvider theme={myTheme}>
            <>
                <SnackbarProvider
                    //preventDuplicate
                    maxSnack={3}
                    style={{ minWidth: 0 }}
                >
                    <Message message={message} closeAll={closeSnackBar} />
                    <Message
                        message={confirm.message}
                        persist={true}
                        actions={confirm.actions}
                    />
                    <Message
                        message={errorMessage}
                        persist={true}
                        variant="error"
                    />
                    <Message
                        link={messageLink.link}
                        linkText={messageLink.text}
                        persist={true}
                        linkColor={themeMode == "dark" ? "#5F72D6" : "#90CAF9"}
                        buttonIconColor={
                            themeMode == "dark" ? "#333333" : "#FFFFFF"
                        }
                    />
                </SnackbarProvider>
                <div className="player">
                    {!isSpectating ? (
                        <>
                            <PlayerAvatar
                                name={playerName}
                                color={playerColor}
                                iconName={playerIconName}
                                selected={playerSelected}
                                style={avatarStyle}
                            />
                            <PlayerName
                                name={playerName}
                                style={
                                    playerSelected
                                        ? {
                                              fontWeight: "bold",
                                              zIndex: 1,
                                              minHeight: "1em",
                                              color: myTheme.palette.text
                                                  .primary,
                                          }
                                        : {
                                              minHeight: "1em",
                                              color: myTheme.palette.text
                                                  .primary,
                                          }
                                }
                            />
                        </>
                    ) : (
                        //TODO: display player if spectating
                        <PlayerAvatar
                            name={""}
                            color={""}
                            iconName={""}
                            selected={playerSelected}
                            style={avatarStyle}
                        />
                    )}
                </div>
                <SpeedDialMenu
                    hidden={(locHash == "" && !hasStarted) || isSpectating}
                    actions={isPlayingComputer ? gameActionsSP : gameActionsMP}
                    icon={<FontAwesomeIcon icon={faFlag} />}
                />
            </>
        </ThemeProvider>
    );
};

export default PageBottom;
