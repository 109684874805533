import "./PageTop.css";
import React, { useState, useEffect } from "react";
import GamesList from "../components/GamesList/GamesList";
import PlayerName from "../components/PlayerName/PlayerName";
import { ChessMenu } from "../components/ChessMenu/ChessMenu";
import { App } from "../../../App";
import ProfileFormDialog from "../components/ProfileFormDialog/ProfileFormDialog";
import { stateInstance } from "../../../ChessState";
import PlayerAvatar from "../components/PlayerAvatar/PlayerAvatar";
import { debounceTime } from "rxjs/operators";
import { Button, IconButton, Link, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AboutDialog from "../components/AboutDialog/AboutDialog";
import { Box } from "@mui/system";
import Badge from "@mui/material/Badge";
import AccountDialog from "../components/AccountDialog/AccountDialog";
import SPGameFormDialog from "../components/SPGameFormDialog/SPGameFormDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, useTheme, createTheme } from "@mui/material/styles";
import { Subscription } from "rxjs";

interface PageTopProps {
    app: App;
}

const PageTop: React.FC<PageTopProps> = ({ app }) => {
    const [profileName, setProfileName] = useState("");
    const [profileColor, setProfileColor] = useState("");
    const [profileIconName, setProfileIconName] = useState("");
    const [isPlayersTurn, setIsPlayersTurn] = useState(false);
    const [opponentName, setOpponentName] = useState("");
    const [opponentColor, setOpponentColor] = useState("#cccccc");
    const [opponentIconName, setOpponentIconName] = useState("cat");
    const [gameLoaded, setGameLoaded] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [gamesVisible, setGamesVisible] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);
    const [aboutVisible, setAboutVisible] = useState(false);
    const [spGameFormVisible, setSpGameFormVisible] = useState(false);
    const [spGameDifficulty, setSpGameDifficulty] = useState(2);
    const [games, setGames] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [numGameMoves, setNumGameMoves] = React.useState(0);
    const [accountVisible, setAccountVisible] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [opponentWon, setOpponentWon] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [isPlayingComputer, setIsPlayingComputer] = useState(false);
    const [themeMode, setThemeMode] = useState<"dark" | "light">("light");
    const [isSpectating, setIsSpectating] = useState(false);

    const themeModeRef = React.useRef(themeMode);
    const numGameMovesRef = React.useRef(numGameMoves);

    const myTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: themeMode,
                },
            }),
        [themeMode]
    );

    const handleMenuIconClick = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const subs: Array<Subscription> = [];

    useEffect(() => {
        subs.push(
            stateInstance.loaded.subscribe((val: boolean) => {
                setGameLoaded(val);
            })
        );
        subs.push(
            stateInstance.hasStarted.subscribe((val: boolean) => {
                setHasStarted(val);
            })
        );
        subs.push(
            stateInstance.playerName.subscribe((val: string) => {
                setProfileName(val);
            })
        );
        subs.push(
            stateInstance.playerColor.subscribe((val: string) => {
                setProfileColor(val);
            })
        );
        subs.push(
            stateInstance.playerIcon.subscribe((val: string) => {
                setProfileIconName(val);
            })
        );
        subs.push(
            stateInstance.isPlayersTurn.subscribe((val: boolean) => {
                setIsPlayersTurn(val);
            })
        );
        subs.push(
            stateInstance.opponentName.subscribe((val: string) => {
                setOpponentName(val);
            })
        );
        subs.push(
            stateInstance.opponentColor.subscribe((val: string) => {
                setOpponentColor(val);
            })
        );
        subs.push(
            stateInstance.opponentIcon.subscribe((val: string) => {
                setOpponentIconName(val);
            })
        );
        subs.push(
            stateInstance.isLoggedIn.subscribe((val: boolean) => {
                setIsLoggedIn(val);
            })
        );
        subs.push(
            stateInstance.games.pipe(debounceTime(200)).subscribe((val: []) => {
                setGames([...val]);
                let numGameMoves = 0;
                val.forEach((game) => {
                    if ((game as any).isUsersTurn) {
                        numGameMoves++;
                    }
                });
                setNumGameMoves(numGameMoves);
                numGameMovesRef.current = numGameMoves;
                updateMenuItems();
            })
        );
        subs.push(
            stateInstance.opponentWon.subscribe((val: boolean) => {
                setOpponentWon(val);
            })
        );
        subs.push(
            stateInstance.isGameOver.subscribe((val: boolean) => {
                setIsGameOver(val);
            })
        );
        subs.push(
            stateInstance.computerAILevel.subscribe((val: number) => {
                setSpGameDifficulty(val);
            })
        );
        subs.push(
            stateInstance.isPlayingComputer.subscribe((val: boolean) => {
                setIsPlayingComputer(val);
            })
        );
        subs.push(
            stateInstance.themeMode.subscribe((val: "light" | "dark") => {
                setThemeMode(val);
                themeModeRef.current = val;
                updateMenuItems();
            })
        );
        subs.push(
            stateInstance.isSpectating.subscribe((val: boolean) => {
                console.log("isSpectating", val);
                setIsSpectating(val);
            })
        );

        updateMenuItems();

        return () => {
            while (subs.length) {
                subs.pop().unsubscribe();
            }
        };
    }, [stateInstance]);

    const toggleGamesList = () => {
        setGamesVisible(!gamesVisible);
    };
    const onCloseGamesList = () => {
        setGamesVisible(false);
    };
    const toggleProfile = () => {
        setProfileVisible(!profileVisible);
    };
    const onCloseProfile = () => {
        setProfileVisible(false);
    };
    const toggleAbout = () => {
        setAboutVisible(!aboutVisible);
    };
    const onCloseAbout = () => {
        setAboutVisible(false);
    };
    const onCloseSpGameForm = () => {
        setSpGameFormVisible(false);
    };
    const toggleAccount = () => {
        setAccountVisible(!accountVisible);
    };
    const onCloseAccount = () => {
        setAccountVisible(false);
    };

    const updateMenuItems = () => {
        const menuItems = [
            { key: 1, label: "About", func: toggleAbout, title: "" },
            { key: 2, label: "Edit Profile", func: toggleProfile, title: "" },
            {
                key: 3,
                label: "Play Computer",
                func: () => {
                    setSpGameFormVisible(true);
                    app.loadGameSP();
                },
                title: "",
            },
            {
                key: 4,
                label: "Play Friends",
                func: toggleGamesList,
                title: "",
                badgeContent: numGameMovesRef.current,
            },
        ];
        if (themeModeRef.current === "dark") {
            menuItems.push({
                key: 5,
                label: "Light Theme",
                func: () => {
                    app.setThemeMode("light");
                },
                title: "",
            });
        } else {
            menuItems.push({
                key: 5,
                label: "Dark Theme",
                func: () => {
                    app.setThemeMode("dark");
                },
                title: "",
            });
        }
        menuItems.push({
            key: 6,
            label: "Account",
            func: toggleAccount,
            title: "",
        });

        setMenuItems(menuItems);
    };

    let avatarStyle = {
        width: "6vh",
        height: "6vh",
        fontSize: "1em",
        marginTop: "0.2em",
    };

    const opponentSelected = (!isGameOver && !isPlayersTurn) || opponentWon;

    if (opponentSelected && !isPlayingComputer) {
        avatarStyle.marginTop = "-.2em";
    }

    return (
        <ThemeProvider theme={myTheme}>
            <>
                <Paper
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        padding: ".4em",
                        borderRadius: "0 0 .3em 0",
                        backgroundColor:
                            themeMode === "dark"
                                ? "#121212"
                                : "rgb(223,223,223)",
                    }}
                    elevation={6}
                >
                    <IconButton aria-label="Menu" onClick={handleMenuIconClick}>
                        <Badge color="primary" badgeContent={numGameMoves}>
                            <MenuIcon />
                        </Badge>
                    </IconButton>
                </Paper>
                <div className="player">
                    {gameLoaded &&
                    !hasStarted &&
                    !isPlayersTurn &&
                    opponentName == "" ? (
                        <div className="top-buttons">
                            <Button
                                sx={{ boxShadow: 3 }}
                                onClick={app.inviteFriend}
                                variant="contained"
                            >
                                Invite Friend
                            </Button>
                        </div>
                    ) : null}
                    {!isSpectating ? (
                        <>
                            <PlayerName
                                name={opponentName}
                                style={
                                    opponentSelected
                                        ? {
                                              fontWeight: "bold",
                                              zIndex: 1,
                                              minHeight: "1em",
                                              color: myTheme.palette.text
                                                  .primary,
                                          }
                                        : {
                                              minHeight: "1em",
                                              color: myTheme.palette.text
                                                  .primary,
                                          }
                                }
                            />
                            <Box style={{ position: "relative" }}>
                                {isPlayingComputer &&
                                !isPlayersTurn &&
                                !isGameOver ? (
                                    <CircularProgress
                                        style={{
                                            width: "8vh",
                                            height: "8vh",
                                            position: "absolute",
                                            left: "-.32em",
                                            top: "-.15em",
                                        }}
                                    />
                                ) : null}
                                <PlayerAvatar
                                    name={opponentName}
                                    color={opponentColor}
                                    iconName={opponentIconName}
                                    selected={
                                        opponentSelected && !isPlayingComputer
                                    }
                                    style={avatarStyle}
                                />
                            </Box>
                        </>
                    ) : (
                        <>
                            <PlayerAvatar
                                name=""
                                color=""
                                iconName=""
                                selected={
                                    opponentSelected && !isPlayingComputer
                                }
                                style={avatarStyle}
                            />
                        </>
                    )}
                </div>
                <ChessMenu
                    items={menuItems}
                    anchorEl={menuAnchorEl}
                    handleClose={handleMenuClose}
                ></ChessMenu>
                <ProfileFormDialog
                    name={profileName}
                    color={profileColor}
                    icon={profileIconName}
                    open={profileVisible}
                    onClose={onCloseProfile}
                    saveProfileFunc={app.saveProfile}
                />
                <AboutDialog
                    open={aboutVisible}
                    content={
                        <>
                            <div>DecentChess v1.0.17</div>
                            <div style={{ margin: ".6em" }}>by aBowman</div>
                            <div>
                                DecentChess is a progressive web app that uses a
                                decentralized database to store user data.
                                Sections of the database are stored amongst the
                                users rather than in one centralized location.
                                Profiles are encrypted and only shared with the
                                friends you invite.
                            </div>
                            <div style={{ marginTop: "1em" }}>
                                Visit{" "}
                                <Link
                                    href="https://www.abowman.com/decentchess/"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    aBowman.com
                                </Link>{" "}
                                for more info / support.
                            </div>
                        </>
                    }
                    onClose={onCloseAbout}
                />
                <SPGameFormDialog
                    difficulty={spGameDifficulty}
                    onChange={(
                        event: Event,
                        value: number,
                        activeThumb: number
                    ) => {
                        setSpGameDifficulty(value);
                        app.setComputerAILevel(value);
                    }}
                    open={spGameFormVisible}
                    onClose={onCloseSpGameForm}
                />
                <GamesList
                    open={gamesVisible}
                    onClose={onCloseGamesList}
                    loadGameFunc={app.loadGame}
                    deleteGameFunc={app.deleteFromMyGames}
                    newGameFunc={app.createNewGameMP}
                    games={games}
                    dialogTitle="Friend Games"
                    selectedID={app.mpgame.gameID}
                />
                <AccountDialog
                    open={accountVisible}
                    onClose={onCloseAccount}
                    onDownloadPrivateKey={app.downloadPrivateKey}
                    onLogIn={app.logIn}
                    onLogOut={app.logOut}
                    onCopyKeys={app.copyKeysToClipboard}
                    onCreateNewAccount={app.createNewAccount}
                    isLoggedIn={isLoggedIn}
                />
            </>
        </ThemeProvider>
    );
};

export default PageTop;
