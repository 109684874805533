import { inject, injectable, singleton } from "tsyringe";
import * as ChessJS from "chess.js";
import { Chessboard } from "./board/Chessboard";
import { SEA } from "gun/gun";
import { WaterGunDB as GunDB } from "./WaterGun/WaterGunDB";

@singleton()
@injectable()
export class ChessGameMP {
    public chessJS: ChessJS.ChessInstance;
    private get gun() {
        return this.gundb.instance;
    }
    private _gunRoom: any;
    private get gunRoom() {
        if (!this._gunRoom) {
            this._gunRoom = this.gundb.create();
        }
        return this._gunRoom;
    }

    public fen = "";
    public gameID = "";
    private userKeys: any;
    public secret: string = "";
    public oppPub: string = "";

    private selectedSquare: string;
    private prevFen = "";
    private eveFirstMove: any;
    private eveLastMove: any;
    private eveCaptured: any;
    private eveNextGame: any;
    private cert: any;
    private _started = false;
    private _loaded = false;
    private resetFen = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR";
    private _winner: "b" | "w";
    private _resigned: "b" | "w";
    private _drawRequested: "b" | "w";
    private _draw: boolean;
    private _drawDenied: "b" | "w";
    private _nextGame: string;

    get nextGame() {
        return this._nextGame;
    }

    get winner() {
        return this._winner;
    }
    get resigned() {
        return this._resigned;
    }
    get drawRequested() {
        return this._drawRequested;
    }
    get drawDenied() {
        return this._drawDenied;
    }
    public inDraw(): boolean {
        return this._draw;
    }

    public inCheck(): boolean {
        return this.chessJS.in_check();
    }

    public inCheckmate(): boolean {
        return this.chessJS.in_checkmate();
    }

    public isGameOver(): boolean {
        return (
            this.chessJS.game_over() || this._winner != null || this.inDraw()
        );
    }

    public isFirstMove(): boolean {
        return this.fen == this.resetFen;
    }

    private reloadGameID: NodeJS.Timeout;

    get hasStarted() {
        return this._started;
    }
    set hasStarted(val: boolean) {
        if (val && !this._started && this._loaded) {
            this._started = val;
            this.notifyListeners("started"); //only notify started when loaded
            return;
        }
        this._started = val;
    }

    get loaded() {
        return this._loaded;
    }
    set loaded(val: boolean) {
        if (val && !this._loaded) {
            this._loaded = val;
            this.notifyListeners("load");
            if (this._started) {
                this.notifyListeners("started");
            }
            return;
        }
        this._loaded = val;
    }

    private _spectator = false;
    get spectator() {
        return this._spectator;
    }
    set spectator(val: boolean) {
        this._spectator = val;
    }

    get side() {
        return this.chessboard.viewSide;
    }

    private _numReloads = 0;
    private _maxReloads = 3;

    constructor(
        private gundb: GunDB,
        @inject("Chessboard") public chessboard: Chessboard
    ) {
        this.onNewMove = this.onNewMove.bind(this);
        this.onFirstMove = this.onFirstMove.bind(this);
        this.onCaptured = this.onCaptured.bind(this);
        this.onNextGame = this.onNextGame.bind(this);
        const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess;
        this.chessJS = new Chess();
        this.handleBoardClick = this.handleBoardClick.bind(this);
        this.setGameOverByDraw = this.setGameOverByDraw.bind(this);
        this.denyDraw = this.denyDraw.bind(this);
    }

    public reset() {
        this.gameID = null;
        this.hasStarted = false;
        this.loaded = false;
        this.lastMove = null;
        this._winner = null;
        this._resigned = null;
        this._draw = null;
        this._drawRequested = null;
        this.spectator = false;
        this.oppPub = "";
        this._nextGame = "";
        if (this._moveQueue.length) {
            console.log("clear move queue");
            this._moveQueue.length = 0;
        }
        this.cert = null;
        this.fen = "";
        this.selectedSquare = null;
        this.chessJS.reset();
        if (this.eveFirstMove) {
            this.eveFirstMove.off();
        }
        if (this.eveLastMove) {
            this.eveLastMove.off();
        }
        if (this.eveCaptured) {
            this.eveCaptured.off();
        }
        if (this.eveNextGame) {
            this.eveNextGame.off();
        }
        if (this.reloadGameID) {
            clearTimeout(this.reloadGameID);
            this.reloadGameID = null;
        }
        this.chessboard.reset();
        this.chessboard.removeEventListener("click", this.handleBoardClick);
    }

    private _listeners: { [eventName: string]: Array<Function> } = {};

    private notifyListeners(eventName: string, data?: any) {
        this._listeners[eventName]?.forEach((func: Function) => func(data));
    }

    addEventListener(
        eventName: "move" | "load" | "started" | "new" | "error" | "next",
        func: Function
    ) {
        if (!this._listeners[eventName]) this._listeners[eventName] = [];
        this._listeners[eventName].push(func);
    }

    removeEventListener(
        eventName: "move" | "load" | "started" | "new" | "error" | "next",
        func: Function
    ) {
        const index = this._listeners[eventName]?.indexOf(func);
        if (index === -1) return;
        this._listeners[eventName].splice(index, 1);
    }

    private onNewMove(move: any, key: string, msg: string, eve: any) {
        this.eveLastMove = eve;

        if (move?.length > 0) {
            move = JSON.parse(move);
        }

        this._winner = move.winner;

        if ((move.winner || move.draw) && !move["viewed_" + this.side]) {
            this.setLastMoveViewed(move);
        }

        if (move.resign?.length > 0) {
            this._resigned = move.resign;
            this.notifyListeners("move");
            return;
        }

        if (move.drawRequest) {
            this._drawRequested = move.drawRequest;
            this.notifyListeners("move");
            return;
        }

        if (move.draw) {
            this._draw = move.draw;
            this.notifyListeners("move");
            return;
        }

        this._draw = false;
        this._drawRequested = null;

        if (move.drawDeny) {
            this._drawDenied = move.drawDeny;
            this.notifyListeners("move");
            return;
        }

        this._drawDenied = null;

        if (!move || !move.flags) {
            return;
        }
        const pieceToMove = this.chessJS.get(move.from);
        if (!pieceToMove) {
            return;
        }
        const lastMove = this.lastMove;
        if (lastMove) {
            if (lastMove.from == move.from && lastMove.to == move.to) {
                return;
            }
        }

        this.chessJS.move(move);

        this.fen = this.chessJS.fen();

        if (!this.cert && !this.hasStarted) {
            //make sure white downloads cert when black first moves
            (async () => {
                this.cert = await this.gun
                    .get("~" + this.gameID)
                    .get("certs")
                    .get("~" + this.userKeys.pub);
            })();
        }

        this.hasStarted = true;

        this.move(move);
    }

    private onFirstMove(move: any, key: string, msg: string, eve: any) {
        this.eveFirstMove = eve;

        if (move?.length > 0) {
            move = JSON.parse(move);
        }

        if (!move || !move.flags) {
            return;
        }
        const pieceToMove = this.chessJS.get(move.from);
        if (!pieceToMove) {
            return;
        }

        this.eveFirstMove.off();

        (async () => {
            if (!this.spectator && this.side == "b" && this.oppPub == "") {
                //current user created the game, need to add opponent using pub from first move data
                await this.addOpponentToGame(this.gameID);
                await this.gun.get("~" + this.gameID).get("w"); //load w to player gun
            } else {
                if (!this.spectator) {
                    this.notifyListeners("new", this.gameID);
                }
            }
            this.chessJS.move(move);
            const newFen = this.chessJS.fen();
            this.fen = newFen;
            this.hasStarted = true;
            this.notifyListeners("started");
            this.move(move);
        })();
    }

    public async resign() {
        if (this.isGameOver()) {
            return;
        }
        const winner = this.side == "w" ? "b" : "w";
        const lastMove: any = {
            winner: winner,
            resign: this.side,
        };
        lastMove["viewed_" + this.side] = true;
        this.prevFen = this.fen;
        await this.createNextGame();
        await this.gun.get("~" + this.gameID).put(
            {
                lastMove: JSON.stringify(lastMove),
                fen: this.fen,
                prevFen: this.prevFen,
            },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put resign error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
    }

    private async createNextGame() {
        if (this.oppPub == "") {
            return;
        }
        if (this.nextGame) {
            return;
        }
        const oppSide = this.side == "w" ? "b" : "w";
        const newSide = oppSide;
        let oppPub = this.oppPub;
        oppPub = oppPub.substring(1); //remove "~"

        let newGameID = await this.createNewGame(
            this.userKeys,
            newSide,
            oppPub,
            true
        );
        await this.gun.get("~" + this.gameID).put(
            { nextGame: newGameID },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put next game error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
        this._nextGame = newGameID;
    }

    public async requestDraw() {
        if (this.isGameOver()) {
            return;
        }
        let lastMove: any = {
            drawRequest: this.side,
        };
        this.prevFen = this.fen;
        this.gun.get("~" + this.gameID).put(
            {
                lastMove: JSON.stringify(lastMove),
                fen: this.fen,
                prevFen: this.prevFen,
            },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put request draw error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
    }

    public async denyDraw() {
        if (this.isGameOver()) {
            return;
        }
        this._drawRequested = null;
        this.gun.get("~" + this.gameID).put(
            {
                lastMove: JSON.stringify({
                    drawDeny: this.side,
                }),
            },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put deny draw error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
    }

    public async setGameOverByDraw() {
        let lastMove: any = { draw: true };
        lastMove["viewed_" + this.side] = true;
        this._drawRequested = null;
        await this.gun.get("~" + this.gameID).put(
            {
                lastMove: JSON.stringify(lastMove),
            },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put draw error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
        await this.createNextGame();
    }

    private async setLastMoveViewed(lastMove: any) {
        const lastMoveViewd = { ...lastMove };
        lastMoveViewd["viewed_" + this.side] = true;
        this.gun.get("~" + this.gameID).put(
            {
                lastMove: JSON.stringify(lastMoveViewd),
            },
            (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put win viewed error", ack.err);
                }
            },
            {
                opt: { cert: await this.getCert() },
            }
        );
    }

    private handleBoardClick(square: string): void {
        const cb = this.chessboard;
        const cjs = this.chessJS;
        const isPlayersTurn =
            cjs.turn() == cb.viewSide && !this._spectator && !this.isGameOver();

        if (!isPlayersTurn) {
            return;
        }

        let possibleMoves: Array<ChessJS.Move>;
        let validMove: ChessJS.Move;

        if (square && this.selectedSquare) {
            possibleMoves = cjs.moves({
                verbose: true,
                square: this.selectedSquare,
            });
            validMove = possibleMoves?.find((element) => element.to == square);
        }

        if (isPlayersTurn && validMove) {
            this.prevFen = this.fen;
            cjs.move(validMove);
            this.fen = cjs.fen();
            this.move(validMove);

            cb.deselectSquare();
            cb.removeAllPossibleMoves();
            this.selectedSquare = null;

            let winner = "";
            if (cjs.game_over()) {
                if (cjs.in_checkmate()) {
                    winner = this.side;
                }
            }

            (async () => {
                const userPub = this.userKeys.pub;
                if (this.prevFen == this.resetFen) {
                    const newMove = {
                        userPub: userPub,
                        ...validMove,
                    };
                    let cert = await this.getCert();
                    if (!cert) {
                        cert = await this.gun
                            .get("~" + this.gameID)
                            .get("certs")
                            .get("w");
                    }
                    await this.gun
                        .get("~" + this.gameID)
                        .get("firstMove")
                        .put(
                            JSON.stringify(newMove),
                            (ack: any) => {
                                if (ack.err) {
                                    this.notifyListeners("error", ack.err);
                                    console.error(
                                        "put firstMove error",
                                        ack.err
                                    );
                                }
                            },
                            { opt: { cert: cert } }
                        );

                    this.hasStarted = true;

                    this.notifyListeners("new", this.gameID);
                } else {
                    let newMove: any = {
                        ...validMove,
                    };
                    if (winner) {
                        newMove.winner = winner;
                    }
                    if (this.chessJS.game_over()) {
                        await this.createNextGame();
                    }
                    await this.gun.get("~" + this.gameID).put(
                        {
                            lastMove: JSON.stringify(newMove),
                            fen: this.fen,
                            prevFen: this.prevFen,
                        },
                        (ack: any) => {
                            if (ack.err) {
                                this.notifyListeners("error", ack.err);
                                console.error("put lastMove error", ack.err);
                            }
                        },
                        {
                            opt: { cert: await this.getCert() },
                        }
                    );
                    if (newMove.captured) {
                        let captured = <string>newMove.captured;
                        if (newMove.color == "b") {
                            captured = captured.toUpperCase();
                        }
                        await this.saveCaptured(captured);
                    }
                    if (this.chessJS.in_draw() && !this.inDraw) {
                        await this.setGameOverByDraw();
                    }
                }
            })();

            return;
        }

        if (this.selectedSquare && this.selectedSquare != square) {
            cb.deselectSquare();
        }

        cb.removeAllPossibleMoves();

        if (isPlayersTurn && square) {
            const moves = cjs.moves({ verbose: true, square: square });
            moves.forEach((item) => cb.addPossibleMove(item.to));
        }

        cb.selectSquare(square);

        this.selectedSquare = square;
    }

    lastMove: ChessJS.Move = null;

    _moveQueue: Array<ChessJS.Move> = [];

    async move(move: ChessJS.Move) {
        this._moveQueue.push({ ...move });
        if (this._moveQueue.length > 1) {
            return;
        }
        while (this._moveQueue.length > 0) {
            this.chessboard.removeLastMoveSquare();
            this.chessboard.addLastMoveSquare(
                this._moveQueue[0].from,
                this._moveQueue[0].to
            );
            await this._move(this._moveQueue[0]);
            this._moveQueue.shift();
        }
    }

    private async _move(move: ChessJS.Move) {
        if (!move || !move.flags) {
            return;
        }

        const cb = this.chessboard;

        this.lastMove = { ...move };

        // move.flags:
        // 'n' - a non-capture
        // 'b' - a pawn push of two squares
        // 'e' - an en passant capture
        // 'c' - a standard capture
        // 'p' - a promotion
        // 'k' - kingside castling
        // 'q' - queenside castling

        if (move.flags.indexOf("q") > -1 || move.flags.indexOf("k") > -1) {
            cb.castle(move.color, <any>move.san);
        }

        await cb.movePieceBySquare(move.from, move.to);

        if (move.flags.indexOf("e") > -1) {
            cb.enPassantCapture(move.to);
        }
        if (move.flags.indexOf("c") > -1) {
            cb.capture(move.to);
        }
        if (move.flags.indexOf("p") > -1) {
            cb.promote(move.to, move.color, move.promotion);
        }

        this.notifyListeners("move");
    }

    async loadGame(gameID: string, userKeys: any) {
        const gun = this.gun;

        this.userKeys = userKeys;

        if (this.gameID === gameID) {
            return;
        }

        this.reset();
        this.chessboard.addEventListener("click", this.handleBoardClick);

        const game = await gun.get("~" + gameID);

        if (!game) {
            if (this._numReloads >= this._maxReloads) {
                this._numReloads = 0;
                return;
            }
            this._numReloads++;
            this.reloadGameID = setTimeout(async () => {
                await this.loadGame(gameID, userKeys);
            }, 500);
            return;
        }

        this.gameID = gameID;
        let oppSide = "b";

        if (game.b && game.b["#"] == "~" + this.userKeys.pub) {
            this.chessboard.viewSide = "b";
            oppSide = "w";
        } else if (game.w && game.w["#"] == "~" + this.userKeys.pub) {
            this.chessboard.viewSide = "w";
        } else {
            this.chessboard.viewSide = "w";
            let firstMove = await gun.get("~" + gameID).get("firstMove");
            if (firstMove.length > 0) {
                firstMove = JSON.parse(firstMove);
            }
            if (
                firstMove &&
                firstMove.userPub != "" &&
                firstMove.userPub != this.userKeys.pub
            ) {
                this.spectator = true;
            }
        }

        if (game[oppSide] && game[oppSide]["#"]) {
            this.oppPub = game[oppSide]["#"];
        }

        const fen = await this.gun.get("~" + gameID).get("fen");

        if (!fen) {
            return;
        }

        const prevFen = await this.gun.get("~" + gameID).get("prevFen");

        if (prevFen && prevFen != "") {
            this.hasStarted = true;
            this.fen = prevFen;
        } else if (fen && this.fen == "") {
            this.fen = fen;
        }

        this.chessJS.load(this.fen);
        this.chessboard.loadFEN(this.fen);
        this.eveNextGame = this.gun
            .get("~" + this.gameID)
            .get("nextGame")
            .on(this.onNextGame);
        this.gun
            .get("~" + this.gameID)
            .get("lastMove")
            .on(this.onNewMove);
        this.gun
            .get("~" + this.gameID)
            .get("captured")
            .on(this.onCaptured);
        if (this.fen == this.resetFen) {
            this.gun
                .get("~" + this.gameID)
                .get("firstMove")
                .on(this.onFirstMove);
        } else {
            this.hasStarted = true;
        }

        if (game.nextGame) {
            this._nextGame = game.nextGame;
        }

        this.loaded = true;
    }

    async createNewGame(
        userKeys: any,
        userSide = "b",
        oppPub: string = null,
        skipNotify = false
    ) {
        const gunRoom = this.gunRoom;
        const gameRoomKeys = <any>await SEA.pair();
        const gameID = gameRoomKeys.pub;
        const room = gunRoom.user();

        const oppSide = userSide == "b" ? "w" : "b";

        room.auth(gameRoomKeys);

        let enc = await SEA.encrypt(gameRoomKeys, userKeys);
        await room
            .get("host")
            .get(userKeys.pub)
            .put(enc, (ack: any) => {
                if (ack.err) {
                    this.notifyListeners("error", ack.err);
                    console.error("put host keys error", ack.err);
                }
            });

        let gameObj: any = {
            fen: this.chessboard.getResetFEN(),
            prevFen: "",
            firstMove: "",
            created: new Date().toISOString(),
        };

        gameObj[userSide] = gunRoom.get("~" + userKeys.pub);

        if (oppPub) {
            gameObj[oppSide] = gunRoom.get("~" + oppPub);
        }

        await room.put(gameObj);

        if (oppPub) {
            await this.createCertForGame(oppPub, room, gameRoomKeys);
        } else {
            const cert = await (<any>SEA).certify(
                ["*"],
                [{ "*": "firstMove" }], //TODO: maybe create a secret PIN to send to friend
                gameRoomKeys
            );
            await room.get("certs").get(oppSide).put(cert);
        }

        await this.createCertForGame(userKeys.pub, room, gameRoomKeys);

        room.leave();

        if (!skipNotify) {
            this.notifyListeners("new", gameID);
        }

        return gameID;
    }

    async createCertForGame(pub: string, room: any, gameRoomKeys: any) {
        const newCert = await (<any>SEA).certify(
            [pub],
            [
                { "#": "firstMove" },
                { "#": "lastMove" },
                { "#": "fen" },
                { "#": "prevFen" },
                { "#": "captured" },
                { "#": "nextGame" },
            ],
            gameRoomKeys
        );
        await room
            .get("certs")
            .get("~" + pub)
            .put(newCert);
    }

    async addOpponentToGame(gameID: string) {
        const gunPlayer = this.gun;
        const gunRoom = this.gunRoom;

        const encKeys = await gunPlayer
            .get("~" + gameID)
            .get("host")
            .get(this.userKeys.pub);

        if (!encKeys) {
            return;
        }

        const gameRoomKeys = <string>await SEA.decrypt(encKeys, this.userKeys);
        let firstMove = await gunPlayer.get("~" + gameID).get("firstMove");

        if (firstMove.length) {
            firstMove = JSON.parse(firstMove);
        }

        const oppPub = firstMove.userPub;
        this.oppPub = "~" + oppPub;

        const room = gunRoom.user();
        room.auth(gameRoomKeys);

        const oppLink = gunRoom.get("~" + oppPub);

        await room.get("w").put(oppLink);
        await room.get("lastMove").put(JSON.stringify(firstMove));
        await this.createCertForGame(oppPub, room, gameRoomKeys);

        room.leave();
    }

    public turn() {
        return this.chessJS.turn();
    }

    public async saveCaptured(piece: string) {
        let totCaptured = await this.gun.get("~" + this.gameID).get("captured");

        if (totCaptured) {
            totCaptured += piece;
        } else {
            totCaptured = piece;
        }
        await this.gun
            .get("~" + this.gameID)
            .get("captured")
            .put(
                totCaptured,
                (ack: any) => {
                    if (ack.err) {
                        this.notifyListeners("error", ack.err);
                        console.error("put captured error", ack.err);
                    }
                },
                {
                    opt: { cert: this.cert },
                }
            );
    }

    onCaptured(data: any, key: string, msg: string, eve: any) {
        this.eveCaptured = eve;
        this.chessboard.setAllCaptured(data);
    }

    onNextGame(data: any, key: string, msg: string, eve: any) {
        this.eveNextGame = eve;
        this._nextGame = data;
        this.notifyListeners("next");
    }

    private async getCert() {
        if (!this.cert) {
            const userPub = this.userKeys.pub;
            return (this.cert = await this.gun
                .get("~" + this.gameID)
                .get("certs")
                .get("~" + userPub));
        } else {
            return this.cert;
        }
    }
}
