import { BehaviorSubject } from "rxjs";

export class State {
    games = new BehaviorSubject([]);
    friends: { [id: string]: BehaviorSubject<any> } = {};
    numGamesToMove = new BehaviorSubject(0);
    isLoggedIn = new BehaviorSubject(false);
    addFriend(id: string, friend: any) {
        const fbs = this.friends[id] || new BehaviorSubject({});
        fbs.next(friend);
        this.friends[id] = fbs;
    }
    addGameToList(
        gameID: string,
        name: string,
        created: string,
        isUsersTurn: boolean,
        result: string
    ) {
        let games = this.games.value;
        const found = games.find((game: any) => {
            if (game.id == gameID) {
                return game;
            }
        });
        games = games ? games : [];
        if (found) {
            found.name = name;
            found.isUsersTurn = isUsersTurn;
            found.result = result;
        } else {
            games.push({
                id: gameID,
                name: name,
                created: created,
                isUsersTurn: isUsersTurn,
                result: result,
            });
        }
        games.sort(this.gameCompare);
        this.games.next(games);
    }
    private gameCompare(a: any, b: any) {
        if (a.created > b.created || (a.created && !b.created)) {
            return -1;
        }
        if (b.created > a.created || (b.created && !a.created)) {
            return 1;
        }
        return 0;
    }
}

const stateInstance = new State();

export { stateInstance };
