import { inject, injectable, singleton } from "tsyringe";
import { Chessboard } from "./board/Chessboard";
import ChessAIWorker from "./ChessAI.worker.ts";

@singleton()
@injectable()
export class ChessGameSP {
    public fen = "";

    game: any;
    worker: Worker;
    turn = "";
    status: any;
    capturedInfo: Array<{ from: string; to: string; piece: string }> = [];
    history: Array<any> = [];
    level = 1;

    _disabled = true;

    private selectedSquare: string;

    public inCheck(): boolean {
        return this.status?.check;
    }

    public inCheckmate(): boolean {
        return this.status?.checkMate;
    }

    public isGameOver(): boolean {
        return this.status?.isFinished;
    }

    get side() {
        return this.chessboard.viewSide;
    }

    constructor(@inject("Chessboard") public chessboard: Chessboard) {
        this.handleBoardClick = this.handleBoardClick.bind(this);
        this.onMessageFromWorker = this.onMessageFromWorker.bind(this);

        this.worker = new ChessAIWorker();
        this.worker.addEventListener("message", this.onMessageFromWorker);
    }

    onMessageFromWorker = async (event: any) => {
        const data = event.data;
        switch (data.cmd) {
            case "new": {
                this.status = data.result;
                this.turn = this.status.turn;
                this.chessboard.loadFEN(event.data.fen);
                if (data.history) {
                    const lastMove = data.history[data.history.length - 1];
                    if (lastMove) {
                        this.chessboard.addLastMoveSquare(
                            lastMove.from,
                            lastMove.to
                        );
                    }
                }
                this.notifyListeners("load", this.status);
                if (!this.status.isFinished && this.status.turn == "black") {
                    this.worker.postMessage({ cmd: "moveAI" });
                }
                break;
            }
            case "undoMove": {
                const moves = data.moves;
                if (this.capturedInfo.length > 0) {
                    let lastCapInfo =
                        this.capturedInfo[this.capturedInfo.length - 1];
                    for (let i = 0; i < moves.length; i++) {
                        const move = moves[i];
                        if (
                            move.from == lastCapInfo.from &&
                            move.to == lastCapInfo.to
                        ) {
                            let capSide: "b" | "w" = "b";
                            if (
                                lastCapInfo.piece ==
                                lastCapInfo.piece.toUpperCase()
                            ) {
                                capSide = "w";
                            }
                            this.chessboard.removeLastCaptured(capSide);
                            this.capturedInfo.pop();
                            lastCapInfo =
                                this.capturedInfo[this.capturedInfo.length - 1];
                            if (!lastCapInfo) {
                                break;
                            }
                        }
                    }
                }
                this.status = data.status;
                this.turn = this.status.turn;
                this.chessboard.removeAllPossibleMoves();
                this.chessboard.deselectSquare();
                this.chessboard.removeLastMoveSquare();
                const lastMove = data.lastMove;
                if (lastMove) {
                    this.chessboard.addLastMoveSquare(
                        lastMove.from,
                        lastMove.to
                    );
                }
                this.chessboard.loadFEN(data.fen);
                this.fen = data.fen;
                this.history = data.history;
                this.notifyListeners("move", this.status);
                break;
            }
            case "exportJSON": {
                this.status = data.result;
                this.turn = this.status.turn;
                break;
            }
            case "move":
            case "moveAI": {
                if (this._disabled) {
                    return;
                }
                const move = data.result;
                const fromSq = Object.keys(move)[0];
                const toSq = move[fromSq];
                const status = data.status;
                const prevCast = this.status?.castling;
                const newCast = status.castling;
                if (prevCast) {
                    if (
                        prevCast.blackLong != newCast.blackLong ||
                        prevCast.blackShort != newCast.blackShort
                    ) {
                        if (toSq == "G8") {
                            this.chessboard.movePieceBySquare("h8", "f8");
                        } else if (toSq == "C8") {
                            this.chessboard.movePieceBySquare("a8", "d8");
                        }
                    } else if (
                        prevCast.whiteLong != newCast.whiteLong ||
                        prevCast.whiteShort != newCast.whiteShort
                    ) {
                        if (toSq == "G1") {
                            this.chessboard.movePieceBySquare("h1", "f1");
                        } else if (toSq == "C1") {
                            this.chessboard.movePieceBySquare("a1", "d1");
                        }
                    }
                }

                const movingPiece = this.chessboard.getPieceAtSquare(fromSq);
                const captured = this.chessboard.getPieceAtSquare(toSq);

                this.chessboard.removeLastMoveSquare();
                this.chessboard.addLastMoveSquare(fromSq, toSq);

                await this.chessboard.movePieceBySquare(fromSq, toSq);
                if (captured) {
                    this.chessboard.capture(toSq);
                    this.capturedInfo.push({
                        from: fromSq,
                        to: toSq,
                        piece: captured,
                    });
                    this.chessboard.addToCaptured(captured);
                }

                if (movingPiece == "p" || movingPiece == "P") {
                    const fromRowNum = parseInt(fromSq.charAt(1));
                    const toRowNum = parseInt(toSq.charAt(1));
                    if (fromRowNum == 2 && toRowNum == 1) {
                        this.chessboard.promote(toSq, "b", "q");
                    } else if (fromRowNum == 7 && toRowNum == 8) {
                        this.chessboard.promote(toSq, "w", "q");
                    }
                }

                this.turn = status.turn;

                if (this.status?.enPassant == toSq) {
                    if (movingPiece.toLowerCase() == "p") {
                        this.chessboard.enPassantCapture(toSq);
                    }
                }

                this.status = status;

                if (!status.isFinished) {
                    if (this.turn.charAt(0) != this.chessboard.viewSide) {
                        this.worker.postMessage({ cmd: "moveAI" });
                    }
                }

                this.fen = data.fen;
                this.history = data.history;
                this.notifyListeners("move", status);
                break;
            }
        }
    };

    undoMove() {
        this.worker.postMessage({ cmd: "undoMove", turn: "white" });
    }

    reset() {
        this._disabled = true;
        this.fen = "";
        this.selectedSquare = null;
        this.capturedInfo.length = 0;
        this.chessboard.reset();
        this.chessboard.removeEventListener("click", this.handleBoardClick);
    }

    async createNewGame(config?: any, history?: any, captured?: any) {
        this.reset();
        this._disabled = false;
        this.worker.postMessage({
            cmd: "new",
            config: config,
            history: history,
        });
        this.chessboard.addEventListener("click", this.handleBoardClick);
        if (captured) {
            this.capturedInfo = captured;
            this.capturedInfo.forEach((cap) => {
                this.chessboard.addToCaptured(cap.piece);
            });
        }
    }

    private _listeners: { [eventName: string]: Array<Function> } = {};

    private notifyListeners(eventName: string, data?: any) {
        this._listeners[eventName]?.forEach((func: Function) => func(data));
    }

    addEventListener(eventName: "move" | "load" | "message", func: Function) {
        if (!this._listeners[eventName]) this._listeners[eventName] = [];
        this._listeners[eventName].push(func);
    }

    removeEventListener(
        eventName: "move" | "load" | "message",
        func: Function
    ) {
        const index = this._listeners[eventName]?.indexOf(func);
        if (index === -1) return;
        this._listeners[eventName].splice(index, 1);
    }

    private handleBoardClick(square: string): void {
        const cb = this.chessboard;
        const isPlayersTurn = this.turn.charAt(0) == cb.viewSide;

        if (!isPlayersTurn) {
            return;
        }

        square = square.toUpperCase();

        if (square && this.selectedSquare) {
            const possibleMoves = this.status.moves[this.selectedSquare];
            const validMove = possibleMoves?.find(
                (toSq: string) => toSq == square
            );
            if (validMove) {
                this.worker.postMessage({
                    cmd: "move",
                    from: this.selectedSquare,
                    to: validMove,
                });
                cb.deselectSquare();
                cb.removeAllPossibleMoves();
                this.selectedSquare = null;
                return;
            }
        }

        if (this.selectedSquare && this.selectedSquare != square) {
            cb.deselectSquare();
        }

        cb.removeAllPossibleMoves();

        if (square) {
            const moves = this.status.moves[square];
            moves?.forEach((toSq: string) => cb.addPossibleMove(toSq));
        }

        cb.selectSquare(square);

        this.selectedSquare = square;
    }

    setAILevel(val: number) {
        this.level = val;
        this.worker.postMessage({ cmd: "setLevel", value: val });
    }

    getCaptured() {
        return this.capturedInfo;
    }
}
