import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
//import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

interface SpeedDialMenuProps {
    hidden?: boolean;
    icon?: React.ReactNode;
    actions?: Array<{ icon: any; name: string; func?: any }>;
}

const SpeedDialMenu: React.FC<SpeedDialMenuProps> = ({
    hidden,
    icon,
    actions,
}) => {
    const [open, setOpen] = React.useState(false);
    const handleFabClick = () => setOpen(!open);
    const handleClose = () => setOpen(false);

    return (
        <Box
            sx={{
                position: "fixed",
                top: "0px",
                left: "0px",
                bottom: "0px",
                // height: "100vh",
                width: "100vw",
                //transform: "translateZ(0px)",
                //flexGrow: 1,
                pointerEvents: "none",
                zIndex: 1000,
            }}
        >
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel=""
                sx={{ position: "fixed", bottom: 12, right: 2 }}
                FabProps={{ size: "small" }}
                icon={icon}
                onClose={handleClose}
                onClick={handleFabClick}
                open={open}
                hidden={hidden}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => {
                            action?.func();
                            handleClose();
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default SpeedDialMenu;
