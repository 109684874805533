import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface IconGroupProps {
    chipData: Array<{
        key: number;
        icon: JSX.Element;
        label: string;
        selected: boolean;
    }>;
    onClick: (key: number) => void;
}

const IconGroup: React.FC<IconGroupProps> = ({ chipData, onClick }) => {
    return (
        <Paper
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
            }}
        >
            {chipData.map((data) => {
                return (
                    <ListItem key={data.key}>
                        <Chip
                            icon={data.icon}
                            label={data.label}
                            onClick={() => {
                                onClick(data.key);
                            }}
                            variant={data.selected ? "outlined" : "filled"}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
};

export default IconGroup;
