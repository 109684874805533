import "./ChessboardSimple.css";
import "./font-style.css";
import { Chessboard } from "./Chessboard";

export class ChessboardSimple implements Chessboard {
    htmlRoot: HTMLElement = null;
    htmlBoard: HTMLElement = null;

    private pieceClasses: { [name: string]: string } = {
        K: "icon-chess-king white-piece",
        Q: "icon-chess-queen white-piece",
        R: "icon-chess-rook white-piece",
        B: "icon-chess-bishop white-piece",
        N: "icon-chess-knight white-piece",
        P: "icon-chess-pawn white-piece",
        k: "icon-chess-king black-piece",
        q: "icon-chess-queen black-piece",
        r: "icon-chess-rook black-piece",
        b: "icon-chess-bishop black-piece",
        n: "icon-chess-knight black-piece",
        p: "icon-chess-pawn black-piece",
        wk: "icon-chess-king white-piece",
        wq: "icon-chess-queen white-piece",
        wr: "icon-chess-rook white-piece",
        wb: "icon-chess-bishop white-piece",
        wn: "icon-chess-knight white-piece",
        wp: "icon-chess-pawn white-piece",
        bk: "icon-chess-king black-piece",
        bq: "icon-chess-queen black-piece",
        br: "icon-chess-rook black-piece",
        bb: "icon-chess-bishop black-piece",
        bn: "icon-chess-knight black-piece",
        bp: "icon-chess-pawn black-piece",
    };
    public selectedDiv: HTMLElement = null;
    public grid: HTMLElement = null;

    private capturedTop: HTMLElement;
    private capturedBot: HTMLElement;
    private labelsLeft: HTMLElement = null;
    private labelsBottom: HTMLElement = null;
    private _viewSide: "w" | "b" = "w";

    set viewSide(val: "b" | "w") {
        if (this._viewSide == val) {
            return;
        }
        this._viewSide = val;
        this.addLabels();
        this.htmlBoard.classList.remove("side-w");
        this.htmlBoard.classList.remove("side-b");
        this.htmlBoard.classList.add("side-" + val);
    }
    get viewSide() {
        return this._viewSide;
    }

    constructor(container?: HTMLElement) {
        this.createHTML();
        if (container) {
            container.appendChild(this.htmlRoot);
        }
        this.handleGridClick = this.handleGridClick.bind(this);
        this.grid.addEventListener("click", this.handleGridClick);
    }

    getResetFEN() {
        return "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR";
    }

    private createHTML() {
        this.htmlRoot = document.createElement("div");
        this.htmlBoard = document.createElement("div");
        this.labelsLeft = document.createElement("div");
        this.grid = document.createElement("div");
        this.labelsBottom = document.createElement("div");

        this.capturedTop = document.createElement("div");
        this.capturedBot = document.createElement("div");
        const capturedTopCont = document.createElement("div");
        const capturedBotCont = document.createElement("div");
        capturedTopCont.classList.add("captured-container");
        capturedBotCont.classList.add("captured-container");

        this.htmlRoot.classList.add("chess-container");
        this.htmlBoard.classList.add("chess-board");
        this.labelsLeft.classList.add("labels-left");
        this.grid.classList.add("board-grid");
        this.labelsBottom.classList.add("labels-bottom");
        this.capturedTop.classList.add("captured-top");
        this.capturedBot.classList.add("captured-bottom");
        this.htmlBoard.append(this.labelsLeft);
        this.htmlBoard.append(this.grid);
        this.htmlBoard.append(this.labelsBottom);
        this.htmlRoot.append(capturedTopCont);
        capturedTopCont.append(this.capturedTop);
        this.htmlRoot.append(this.htmlBoard);
        this.htmlRoot.append(capturedBotCont);
        capturedBotCont.append(this.capturedBot);

        for (let i = 0; i < 64; i++) {
            const div = document.createElement("div");
            this.grid.appendChild(div);
            if (i % 2 === 0) {
                div.classList.add("light-square");
            }
        }
        for (let i = 0; i < 8; i++) {
            this.labelsLeft.append(document.createElement("div"));
            this.labelsBottom.append(document.createElement("div"));
        }
        this.addLabels();
    }

    private getSquareCoordinateByIndex(index: number) {
        if (this._viewSide == "b") {
            return "hgfedcba".charAt(index % 8) + (1 + Math.floor(index / 8));
        } else {
            return "abcdefgh".charAt(index % 8) + (8 - Math.floor(index / 8));
        }
    }

    private getSquareShadeByIndex(index: number) {
        if (Math.floor(index / 8) % 2) {
            if (index % 2) {
                return "light-square";
            }
        } else {
            if (index % 2 === 0) {
                return "light-square";
            }
        }
        return "dark-square";
    }

    private addLabels() {
        for (let i = 0; i < 8; i++) {
            let num = this._viewSide == "b" ? 8 - i : i + 1;
            this.labelsLeft.children[7 - i].innerHTML = "" + num;
            this.labelsBottom.children[i].innerHTML = "abcdefgh".charAt(
                num - 1
            );
        }
        for (let i = 0; i < 64; i++) {
            let square = this.grid.children[i];
            square.className = "";
            square.classList.add(this.getSquareCoordinateByIndex(i));
            square.classList.add(this.getSquareShadeByIndex(i));
        }
    }

    loadFEN(str: string) {
        const squares = this.grid.children;
        for (let i = 0; i < squares.length; i++) {
            squares[i].innerHTML = "";
        }
        let squareNum = 0;
        let sign = 1;
        if (this._viewSide == "b") {
            squareNum = squares.length - 1;
            sign = -1;
        }
        for (let i = 0; i < str.length; i++) {
            if (squareNum > squares.length - 1 || squareNum < 0) {
                break;
            }
            const c = str.charAt(i);
            if (c >= "0" && c <= "9") {
                squareNum += parseInt(c) * sign;
            } else if (this.pieceClasses[c]) {
                squares[
                    squareNum
                ].innerHTML = `<span class="${this.pieceClasses[c]}"></span>`;
                squareNum += sign;
            }
        }
    }

    reset(viewSide: "w" | "b" = "w") {
        this.removeAllPossibleMoves();
        this.deselectSquare();
        this.removeLastMoveSquare();
        this.capturedBot.innerHTML = "";
        this.capturedTop.innerHTML = "";
        this.viewSide = viewSide;
        this._allCaptured = "";
        this.loadFEN(this.getResetFEN());
    }

    castle(color: "b" | "w", side: "O-O-O" | "O-O") {
        //side O-O-O = queen
        //side O-O = king
        if (color == "w") {
            if (side == "O-O") {
                this.movePieceBySquare("h1", "f1");
            } else if (side == "O-O-O") {
                this.movePieceBySquare("a1", "d1");
            }
        } else if (color == "b") {
            if (side == "O-O") {
                this.movePieceBySquare("h8", "f8");
            } else if (side == "O-O-O") {
                this.movePieceBySquare("a8", "d8");
            }
        }
    }

    promote(
        square: string,
        color: "b" | "w",
        promotion: "q" | "n" | "r" | "b"
    ) {
        const sqEl = <HTMLElement>(
            this.grid.querySelector("." + square.toLowerCase())
        );
        sqEl.children[0].className = this.pieceClasses[color + promotion];
    }

    enPassantCapture(square: string) {
        let squareBehind = square.charAt(0).toLowerCase();
        let rowNum = parseInt(square.charAt(1));
        if (rowNum < 5) {
            squareBehind += rowNum + 1;
        } else {
            squareBehind += rowNum - 1;
        }
        const toEl = <HTMLElement>this.grid.querySelector("." + squareBehind);
        let pieceToCapture = toEl.children[0];
        if (pieceToCapture) {
            toEl.removeChild(pieceToCapture);
            toEl.children;
        }
    }

    capture(square: string) {
        const toEl = <HTMLElement>(
            this.grid.querySelector("." + square.toLocaleLowerCase())
        );
        if (toEl.children.length <= 1) {
            //only capture when there are 2 on square
            return;
        }
        let pieceToCapture = toEl.children[0];
        if (pieceToCapture) {
            toEl.removeChild(pieceToCapture);
        }
    }

    async movePieceBySquare(fromSquare: string, toSquare: string) {
        const fromEl = <HTMLElement>(
            this.grid.querySelector("." + fromSquare.toLocaleLowerCase())
        );
        const toEl = <HTMLElement>(
            this.grid.querySelector("." + toSquare.toLocaleLowerCase())
        );
        await this.movePieceBySquareElement(fromEl, toEl);
    }

    private async movePieceBySquareElement(
        fromSquareEl: HTMLElement,
        toSquareEl: HTMLElement
    ) {
        const pieceToMove = <HTMLElement>fromSquareEl?.children[0];
        if (!pieceToMove) {
            return;
        }
        if (!toSquareEl) {
            return;
        }
        pieceToMove.style.transition = "transform .5s ease-in-out";
        const bcrOld = fromSquareEl.getBoundingClientRect();
        const bcrNew = toSquareEl.getBoundingClientRect();
        const xpos = bcrNew.left - bcrOld.left;
        const ypos = bcrNew.top - bcrOld.top;
        pieceToMove.style.transform =
            "translate3d(" + xpos + "px, " + ypos + "px, 0px)";
        pieceToMove.style.zIndex = "1";

        return new Promise((resolve, reject) => {
            pieceToMove.addEventListener(
                "transitionend",
                function () {
                    pieceToMove.style.zIndex = "";
                    pieceToMove.style.transition = "";
                    pieceToMove.style.transform = "translate3d(0, 0, 0)";
                    toSquareEl.appendChild(pieceToMove);
                    resolve("move completed");
                },
                true
            );
        });
    }

    deselectSquare() {
        this.selectedDiv?.classList.remove("selected");
        this.selectedDiv = null;
    }

    removeAllPossibleMoves() {
        const els = this.grid.querySelectorAll(".move-option");
        els.forEach((el) => el.classList.remove("move-option"));
    }

    addPossibleMove(square: string) {
        const toEl = <HTMLElement>(
            this.grid.querySelector("." + square.toLowerCase())
        );
        toEl.classList.add("move-option");
    }

    addLastMoveSquare(fromSq: string, toSq: string) {
        const fromEl = <HTMLElement>(
            this.grid.querySelector("." + fromSq.toLowerCase())
        );
        fromEl.classList.add("last-move");
        const toEl = <HTMLElement>(
            this.grid.querySelector("." + toSq.toLowerCase())
        );
        toEl.classList.add("last-move");
    }

    removeLastMoveSquare() {
        const els = this.grid.querySelectorAll(".last-move");
        els.forEach((el) => el.classList.remove("last-move"));
    }

    selectSquare(square: string) {
        const sqEl = <HTMLElement>(
            this.grid.querySelector("." + square.toLowerCase())
        );
        this.selectedDiv = sqEl;
        this.selectedDiv.classList.add("selected");
    }

    private _listeners: { [eventName: string]: Array<Function> } = {};

    addEventListener(eventName: "click", func: (square: string) => void) {
        this._listeners[eventName] = !this._listeners[eventName]
            ? []
            : this._listeners[eventName];
        this._listeners[eventName].push(func);
    }

    removeEventListener(eventName: "click", func: (square: string) => void) {
        const index = this._listeners[eventName]?.indexOf(func);
        if (index >= 0) {
            this._listeners[eventName].splice(index, 1);
        }
    }

    private notifyListeners(eventName: string, data?: any) {
        this._listeners[eventName]?.forEach((func: Function) => func(data));
    }

    private handleGridClick(e: PointerEvent) {
        const eventTarg = <HTMLElement>e.target;
        this.notifyListeners("click", eventTarg.classList[0]);
        this.selectedDiv = eventTarg;
    }

    private _allCaptured = "";

    setAllCaptured(pieces: string) {
        this.capturedTop.innerHTML = "";
        this.capturedBot.innerHTML = "";
        this._allCaptured = pieces;
        for (let i = 0; i < pieces.length; i++) {
            const piece = pieces.charAt(i);
            this.addToCaptured(piece);
        }
    }

    getAllCaptured() {
        return this._allCaptured;
    }

    addToCaptured(piece: string) {
        if (!this.pieceClasses[piece]) {
            return;
        }
        this._allCaptured += piece;
        const el = document.createElement("div");
        el.className = this.pieceClasses[piece];
        const color = piece == piece.toUpperCase() ? "w" : "b";
        if (this.viewSide == color) {
            this.capturedTop.appendChild(el);
        } else {
            this.capturedBot.appendChild(el);
        }
    }

    removeLastCaptured(side: "b" | "w") {
        this.capturedTop.children;
        if (this.viewSide == side) {
            this.capturedTop.removeChild(this.capturedTop.lastChild);
        } else {
            this.capturedBot.removeChild(this.capturedBot.lastChild);
        }
    }

    getPieceAtSquare(square: string): string {
        const sqEl = <HTMLElement>(
            this.grid.querySelector("." + square.toLocaleLowerCase())
        );
        if (sqEl?.children[0]) {
            for (const key in this.pieceClasses) {
                if (this.pieceClasses[key] === sqEl.children[0].className) {
                    return key;
                }
            }
        }
        return null;
    }
}
