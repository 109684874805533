import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, IconButton, ListItemText } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PlayerAvatar from "../PlayerAvatar/PlayerAvatar";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemButton from "@mui/material/ListItemButton";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
//import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles(() => ({
//     usersTurn: {
//         borderLeft: "10px solid",
//         borderColor: "#000 #000 #000 #0f0",
//         marginLeft: "-10px",
//     },
// }));

interface GamesListProps {
    loadGameFunc: (id: string) => void;
    deleteGameFunc: (id: string) => void;
    newGameFunc: () => void;
    open: boolean;
    games: Array<{
        id: string;
        name: string;
        created: string;
        isUsersTurn: boolean;
    }>;
    onClose: () => void;
    dialogTitle: string;
    selectedID: string;
}

const GamesList: React.FC<GamesListProps> = ({
    loadGameFunc,
    deleteGameFunc,
    newGameFunc,
    open,
    games,
    onClose,
    dialogTitle,
    selectedID,
}) => {
    const theme = useTheme();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {dialogTitle}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{ padding: "0", width: "min(300px, 80vw)" }}
            >
                <List>
                    <ListItemButton
                        onClick={() => {
                            newGameFunc();
                            onClose();
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Create New Game" />
                    </ListItemButton>
                    {games.map(
                        (game: {
                            id: string;
                            name: string;
                            created: string;
                            isUsersTurn: boolean;
                            result: string;
                        }) => (
                            <ListItem
                                disablePadding
                                key={game.id}
                                selected={selectedID == game.id}
                                autoFocus={selectedID == game.id}
                                // className={
                                //     game.isUsersTurn ? classes.usersTurn : null
                                // }
                                sx={
                                    game.isUsersTurn
                                        ? {
                                              borderLeft: 10,
                                              //   marginLeft: "-10px",
                                              borderColor:
                                                  theme.palette.primary.main,
                                          }
                                        : null
                                }
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            deleteGameFunc(game.id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemButton
                                    onClick={() => {
                                        loadGameFunc(game.id);
                                        onClose();
                                    }}
                                >
                                    <ListItemAvatar>
                                        <PlayerAvatar
                                            name={game.name}
                                            color="#cccccc"
                                            iconName=""
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={game.name} />
                                    <ListItemText
                                        primary={game.result}
                                        sx={{
                                            textAlign: "right",
                                            // position: "absolute",
                                            // right: 20,
                                            // top: 8,
                                            //color: (theme) => theme.palette.grey[500],
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default GamesList;
