import Gun from "gun/gun";
import { singleton } from "tsyringe";

@singleton()
export class WaterGunDB {
    private _instance: any;

    get instance(): any {
        if (!this._instance) {
            this._instance = this.create();
        }
        return this._instance;
    }

    constructor() {
        require("gun/sea");
        require("gun/lib/then");
        require("gun/lib/radix");
        require("gun/lib/radisk");
        require("gun/lib/store");
        require("gun/lib/rindexed");
    }

    create() {
        return <any>Gun({
            peers: ["https://water-gun.abowman.com/gun"],
            //peers: ["http://localhost:8765/gun"],
            localStorage: false,
        });
    }
}
