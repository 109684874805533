export class Helper {
    static Download(
        filename: string,
        data: string,
        type = "text/plain",
        charset = "utf-8",
        href: string = null
    ) {
        let hiddenElement: HTMLAnchorElement;

        hiddenElement = document.createElement("a");
        hiddenElement.href =
            href || `data:${type};charset=${charset},${encodeURI(data)}`;
        hiddenElement.target = "_blank";
        hiddenElement.download = filename;
        return hiddenElement.click();
    }
}
