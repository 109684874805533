import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import { CSSProperties } from "react";
//import { CSSProperties } from "@material-ui/styles";

interface PlayerAvatarProps {
    name: string;
    color: string;
    iconName: any;
    selected?: boolean;
    style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
    selected: {
        borderStyle: "double",
        borderWidth: ".7em",
    },
}));

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({
    name,
    color,
    iconName,
    selected,
    style,
}) => {
    const classes = useStyles();

    const getInitialsFromName = (name: string) => {
        if (!name) {
            return;
        }
        if (name == "Waiting...") {
            return "?";
        }
        name = name.trim();
        let initials = name.charAt(0);
        if (name.indexOf(" ") > -1) {
            const arr = name.split(" ");
            initials += arr[1].charAt(0);
        }
        return initials;
    };

    return (
        <Avatar
            style={{ ...style, backgroundColor: color }}
            className={selected ? classes.selected : null}
        >
            {iconName != "font" && iconName != "" && iconName != undefined ? (
                <FontAwesomeIcon icon={["fas", iconName]} />
            ) : (
                getInitialsFromName(name)
            )}
        </Avatar>
    );
};

export default PlayerAvatar;
