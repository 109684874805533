import { Input } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

interface AccountDialogProps {
    open: boolean;
    onDownloadPrivateKey: () => void;
    onClose: () => void;
    onLogIn: (keys: string) => void;
    onLogOut: () => void;
    onCopyKeys: () => boolean;
    onCreateNewAccount: () => void;
    isLoggedIn: boolean;
}

const AccountDialog: React.FC<AccountDialogProps> = ({
    open,
    onClose,
    onDownloadPrivateKey,
    onLogIn,
    onLogOut,
    onCopyKeys,
    isLoggedIn,
    onCreateNewAccount,
}) => {
    const [showCopied, setShowCopied] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            style={{ textAlign: "center" }}
            maxWidth="xs"
        >
            <DialogTitle id="form-dialog-title">Account</DialogTitle>
            <DialogContent>
                {!isLoggedIn ? (
                    <>
                        <Input
                            id="login"
                            placeholder="paste private key to log in"
                            onInput={(e) => {
                                onLogIn((e.target as any).value);
                            }}
                        />
                        <p>
                            Paste your private key above or create a new account
                            and private key below. No personal info is required.
                        </p>
                        <Button onClick={onCreateNewAccount} color="primary">
                            Create Account / Private Key
                        </Button>
                    </>
                ) : (
                    ""
                )}

                {isLoggedIn ? (
                    <>
                        <Button onClick={onDownloadPrivateKey} color="primary">
                            Download PRIVATE Key
                        </Button>
                        <br />
                        <Button
                            onClick={() => {
                                if (onCopyKeys()) {
                                    setShowCopied(true);
                                    setTimeout(() => {
                                        setShowCopied(false);
                                    }, 3000);
                                }
                            }}
                            color="primary"
                        >
                            {showCopied ? "Key Copied" : "Copy PRIVATE Key"}
                        </Button>
                        <p>
                            Make sure to SAVE your PRIVATE KEY before logging
                            out. You will not be able to log back in to your
                            account without it.
                        </p>
                        <Button
                            onClick={() => {
                                if (
                                    confirm("Are you sure you want to log out?")
                                ) {
                                    onLogOut();
                                }
                            }}
                            color="primary"
                        >
                            Log Out
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccountDialog;
