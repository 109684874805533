import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconGroup from "../IconGroup/IconGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCat,
    faDog,
    faHorse,
    faHorseHead,
    faCrow,
    faDove,
    faFrog,
    faFish,
    faOtter,
    faDragon,
    faSpider,
    faPaw,
    faHippo,
    faKiwiBird,
    faUserAlt,
    faFont,
    faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import PlayerAvatar from "../PlayerAvatar/PlayerAvatar";

library.add(
    faCat,
    faDog,
    faCrow,
    faHorse,
    faHorseHead,
    faDove,
    faFrog,
    faFish,
    faOtter,
    faDragon,
    faSpider,
    faPaw,
    faHippo,
    faKiwiBird,
    faUserAlt,
    faFont,
    faDesktop
);

interface ProfileFormDialogProps {
    name: string;
    color: string;
    icon: string;
    saveProfileFunc: (name: string, color: string, icon: string) => void;
    open: boolean;
    onClose: () => void;
}

const defaultChipData = [
    {
        key: 1,
        icon: <FontAwesomeIcon icon={["fas", "font"]} />,
        label: "Initials",
        selected: false,
    },
    {
        key: 2,
        icon: <FontAwesomeIcon icon={["fas", "user-alt"]} />,
        label: "User",
        selected: false,
    },
    {
        key: 3,
        icon: <FontAwesomeIcon icon={["fas", "cat"]} />,
        label: "Cat",
        selected: false,
    },
    {
        key: 4,
        icon: <FontAwesomeIcon icon={["fas", "dog"]} />,
        label: "Dog",
        selected: false,
    },
    {
        key: 5,
        icon: <FontAwesomeIcon icon={["fas", "dove"]} />,
        label: "Dove",
        selected: false,
    },
    {
        key: 6,
        icon: <FontAwesomeIcon icon={["fas", "crow"]} />,
        label: "Crow",
        selected: false,
    },
    {
        key: 7,
        icon: <FontAwesomeIcon icon={["fas", "horse"]} />,
        label: "Horse",
        selected: false,
    },
    {
        key: 8,
        icon: <FontAwesomeIcon icon={["fas", "frog"]} />,
        label: "Frog",
        selected: false,
    },
    {
        key: 9,
        icon: <FontAwesomeIcon icon={["fas", "fish"]} />,
        label: "Fish",
        selected: false,
    },
    {
        key: 10,
        icon: <FontAwesomeIcon icon={["fas", "otter"]} />,
        label: "Otter",
        selected: false,
    },
    {
        key: 11,
        icon: <FontAwesomeIcon icon={["fas", "dragon"]} />,
        label: "Dragon",
        selected: false,
    },
    {
        key: 12,
        icon: <FontAwesomeIcon icon={["fas", "spider"]} />,
        label: "Spider",
        selected: false,
    },
    {
        key: 13,
        icon: <FontAwesomeIcon icon={["fas", "paw"]} />,
        label: "Paw",
        selected: false,
    },
    {
        key: 14,
        icon: <FontAwesomeIcon icon={["fas", "hippo"]} />,
        label: "Hippo",
        selected: false,
    },
    {
        key: 15,
        icon: <FontAwesomeIcon icon={["fas", "kiwi-bird"]} />,
        label: "Kiwi-bird",
        selected: false,
    },
];

const ProfileFormDialog: React.FC<ProfileFormDialogProps> = ({
    name,
    color,
    icon,
    saveProfileFunc,
    open,
    onClose,
}) => {
    const [stateName, setStateName] = React.useState("");
    const [stateColor, setStateColor] = React.useState("");
    const [stateIcon, setStateIcon] = React.useState("");
    const [chipData, setChipData] = React.useState(defaultChipData);

    const [defaultName, setDefaultName] = React.useState("");
    const [defaultColor, setDefaultColor] = React.useState("");
    const [defaultIcon, setDefaultIcon] = React.useState("");

    const onChangeName = (e: any) => {
        setStateName(e.target.value);
    };

    const onChangeColor = (e: any) => {
        setStateColor(e.target.value);
    };

    const onClickIcon = (key: number) => {
        const item = defaultChipData.find((item) => {
            if (key == item.key) {
                return item;
            }
        });
        let selectedIcon = item.icon.props.icon[1];
        setStateIcon(selectedIcon);
        updateSelectedIcon(selectedIcon);
    };

    const updateSelectedIcon = (selectedIcon: string) => {
        chipData.forEach((item) => {
            if (item.icon.props.icon[1] == selectedIcon) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        setChipData([...chipData]);
    };

    useEffect(() => {
        setStateName(name);
        setStateColor(color);
        setStateIcon(icon);
        updateSelectedIcon(icon);
        setDefaultName(name);
        setDefaultColor(color);
        setDefaultIcon(icon);
    }, [name, color, icon]);

    const resetForm = () => {
        setStateName(defaultName);
        setStateColor(defaultColor);
        setStateIcon(defaultIcon);
        updateSelectedIcon(defaultIcon);
    };

    const updateDefaultValues = () => {
        setDefaultName(stateName);
        setDefaultColor(stateColor);
        setDefaultIcon(stateIcon);
        updateSelectedIcon(stateIcon);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            style={{ textAlign: "center" }}
            maxWidth="xs"
        >
            <DialogTitle id="form-dialog-title">Edit Profile</DialogTitle>
            <DialogContent>
                <PlayerAvatar
                    name={stateName}
                    color={stateColor}
                    iconName={stateIcon}
                    style={{ margin: "auto" }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    placeholder="Name"
                    type="text"
                    inputProps={{ style: { textAlign: "center" } }}
                    value={stateName}
                    onChange={onChangeName}
                />
                <br />
                <TextField
                    margin="dense"
                    id="color"
                    label="Color"
                    type="color"
                    style={{ width: "50%" }}
                    value={stateColor}
                    onChange={onChangeColor}
                />
                <IconGroup chipData={chipData} onClick={onClickIcon} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        resetForm();
                        onClose();
                    }}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        updateDefaultValues();
                        saveProfileFunc(stateName, stateColor, stateIcon);
                        onClose();
                    }}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProfileFormDialog;
