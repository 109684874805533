import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";

interface MenuProps {
    items: Array<{
        key: number;
        label: string;
        func: Function;
        title?: string;
        badgeContent?: number;
    }>;
    anchorEl?: any;
    handleClose: any;
}

export const ChessMenu: React.FC<MenuProps> = ({
    items,
    anchorEl,
    handleClose,
}) => {
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map((item) => {
                    return (
                        <MenuItem
                            key={item.key}
                            title={item.title}
                            onClick={() => {
                                item.func();
                                handleClose();
                            }}
                        >
                            <Badge
                                badgeContent={item.badgeContent}
                                color="primary"
                            >
                                {item.label}
                            </Badge>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
