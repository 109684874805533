import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Slider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

const marks = [
    {
        value: 0,
        label: "Easiest",
    },
    {
        value: 1,
        label: "",
    },
    {
        value: 2,
        label: "",
    },
    {
        value: 3,
        label: "Hardest",
    },
];

function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
}

interface SPGameFormDialogProps {
    difficulty: number;
    onChange: (
        event: Event,
        value: number | number[],
        activeThumb: number
    ) => void;
    open: boolean;
    onClose: () => void;
}

const SPGameFormDialog: React.FC<SPGameFormDialogProps> = ({
    difficulty,
    onChange,
    open,
    onClose,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            style={{ textAlign: "center" }}
            maxWidth="md"
        >
            <DialogTitle id="form-dialog-title">
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        width: "min(40vw, 200px)",
                        margin: "1em",
                        marginTop: "2em",
                    }}
                >
                    <Slider
                        key={`slider-${difficulty}`}
                        aria-label="Difficulty"
                        defaultValue={difficulty}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        step={1}
                        min={0}
                        max={3}
                        marks={marks}
                        onChange={onChange}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SPGameFormDialog;
