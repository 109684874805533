import "./index.css";
import "reflect-metadata";
import { container } from "tsyringe";
import { ChessboardSimple } from "./board/ChessboardSimple";
import { App } from "./App";
import ReactDOM from "react-dom";
import PageTop from "./ui/react/PageTop/PageTop";
import PageBottom from "./ui/react/PageBottom/PageBottom";

window.addEventListener("load", () => {
    container.register("Chessboard", {
        useValue: new ChessboardSimple(document.getElementById("chessboard")),
    });
    const app = container.resolve(App);

    ReactDOM.render(<PageTop app={app} />, document.getElementById("ui-top"));
    ReactDOM.render(
        <PageBottom app={app} />,
        document.getElementById("ui-bottom")
    );
});

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("SW registered: ", registration);
            })
            .catch((registrationError) => {
                console.log("SW registration failed: ", registrationError);
            });
    });
}
