import { CSSProperties } from "react";

interface PlayerNameProps {
    name: string;
    style?: CSSProperties;
}

const PlayerName: React.FC<PlayerNameProps> = ({ name, style }) => {
    return (
        <div className="player-name" style={style}>
            {name}
        </div>
    );
};

export default PlayerName;
