import "./Message.css";
import { Fragment, useEffect } from "react";
import { useSnackbar, VariantType } from "notistack";
import { Button, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MessageProps {
    message?: string | React.ReactNode;
    link?: string;
    linkText?: string;
    linkColor?: string;
    buttonIconColor?: string;
    actions?: Array<{ text: string; func: Function }>;
    persist?: boolean;
    variant?: VariantType;
    preventDuplicate?: boolean;
    closeAll?: boolean;
}

const Message: React.FC<MessageProps> = ({
    message,
    link,
    linkText,
    linkColor,
    buttonIconColor,
    actions,
    persist,
    variant = "default",
    closeAll,
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (closeAll) {
            closeSnackbar();
            return;
        }
        if (
            (message && message != "") ||
            (link && link != "") ||
            (linkText && linkText != "")
        ) {
            let action = null;
            if (link && message) {
                action = (key: string) => (
                    <Fragment>
                        <Button
                            onClick={() => {
                                location.href = link;
                            }}
                        >
                            {linkText}
                        </Button>
                        <IconButton
                            onClick={() => {
                                closeSnackbar(key);
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                    </Fragment>
                );
            } else if (link && !message) {
                if (link && linkText) {
                    message = (
                        <Link color={linkColor} href={link}>
                            {linkText}
                        </Link>
                    );
                    action = (key: string) => (
                        <Fragment>
                            <IconButton
                                onClick={() => {
                                    closeSnackbar(key);
                                }}
                            >
                                <CloseIcon style={{ color: buttonIconColor }} />
                            </IconButton>
                        </Fragment>
                    );
                }
            }
            if (actions) {
                action = (key: string) => (
                    <Fragment>
                        {actions.map(
                            (
                                a: { text: string; func: Function },
                                index: number
                            ) => {
                                return (
                                    <Button
                                        key={index}
                                        onClick={() => {
                                            a.func();
                                        }}
                                    >
                                        {a.text}
                                    </Button>
                                );
                            }
                        )}
                    </Fragment>
                );
            }

            const key = enqueueSnackbar(message, {
                onClick: () => {
                    closeSnackbar(key);
                },
                persist: persist,
                action,
                variant: variant,
                //transitionDuration: { appear: 0, enter: 0, exit: 0 },
            });
        }
    }, [message, actions, link, linkText, closeAll]);

    return <></>;
};

export default Message;
