import { BehaviorSubject } from "rxjs";
import { stateInstance as waterGunState } from "./WaterGun/WaterGunState";

export class ChessState {
    themeMode = new BehaviorSubject<"light" | "dark">("light");
    playerName = new BehaviorSubject("");
    playerColor = new BehaviorSubject("#cccccc");
    playerIcon = new BehaviorSubject("");
    isPlayersTurn = new BehaviorSubject(false);
    hasStarted = new BehaviorSubject(false);
    message = new BehaviorSubject("");
    errorMessage = new BehaviorSubject("");
    opponentName = new BehaviorSubject("");
    opponentIcon = new BehaviorSubject("");
    opponentColor = new BehaviorSubject("#cccccc");
    loaded = new BehaviorSubject(false);
    fen = new BehaviorSubject("");
    side = new BehaviorSubject("");
    isPlayingComputer = new BehaviorSubject(false);
    computerAILevel = new BehaviorSubject(1);
    opponentWon = new BehaviorSubject(false);
    playerWon = new BehaviorSubject(false);
    isGameOver = new BehaviorSubject(false);
    isSpectating = new BehaviorSubject(false);
    gameID = new BehaviorSubject("");
    confirm = new BehaviorSubject({
        message: "",
        actions: null,
    });
    messageLink = new BehaviorSubject({
        text: "",
        link: "",
    });
    get friends() {
        return waterGunState.friends;
    }
    get games() {
        return waterGunState.games;
    }
    get isLoggedIn() {
        return waterGunState.isLoggedIn;
    }
}

const stateInstance = new ChessState();

export { stateInstance };
